import { useEffect, useState } from "react";
import './FullAutoStep1.css';


function FullAutoStep1({selectedStep, mailingLists, selectedMailingList, setSelectedMailingList, selectedMailingListID, setSelectedMailingListID}) {

    const [listSearchText, setListSearchText] = useState('');
    const [filteredMailingList, setFilteredMailingList] = useState([]);

    
    useEffect(() => {
        setFilteredMailingList(mailingLists)
        setListSearchText('')
    }, [mailingLists])


    useEffect(() => {
        if (listSearchText !== '') {
          const filteredMailingLists = mailingLists.filter(x => x.table_name.toLowerCase().includes(listSearchText.toLowerCase()))
          setFilteredMailingList(filteredMailingLists)
        } else {
            setFilteredMailingList(mailingLists)
        }
    }, [listSearchText])





  return (
    <div className={selectedStep == 1 ? "FullAutoStep1Container" : "FullAutoStep1ContainerHidden"}>
        <div className="FullAutoStep1Left">
            <span className="FullAutoStep1LeftStepNumber">Step 1</span>
            <span className="FullAutoStep1LeftTitle">Select a mailing list</span>
        </div>
        <div className="FullAutoStep1Middle">
            {/* <span className="FullAutoStep1MiddleTitle">Select a mailing list</span>
            <span className="FullAutoStep1MiddleSubTitle">Select the mailing list you want to send your campaign to.</span> */}
            <div className="FullAutoStep1MiddleMailingListContainer">
                <input className="FullAutoStep1MiddleMailingListSearch" type="text" placeholder="Search for a mailing list" onChange={(e) => {setListSearchText(e.target.value)}} value={listSearchText} />
                {filteredMailingList.map((x) => (
                    <div className={x.master_id == selectedMailingList.master_id ? "FullAutoStep1MiddleMailingListSelected" : "FullAutoStep1MiddleMailingList"} onClick={() => {
                        setSelectedMailingList(x);
                        setSelectedMailingListID(x.master_id);
                    }}>
                        <span className="FullAutoStep1MiddleMailingListName">{x.table_name}</span>
                    </div>
                ))}
            </div>
        </div>
        <div className="FullAutoStep1Right">

        </div>
    </div>
  );
}


export default FullAutoStep1;