// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { GoogleAuthProvider } from "firebase/auth";

/*
const firebaseConfig = {
  apiKey: "AIzaSyBRCMcZv94VXJJKDdLTx-EUzFyqTp62Wuk",
  authDomain: "t3-tools.firebaseapp.com",
  projectId: "t3-tools",
  storageBucket: "t3-tools.appspot.com",
  messagingSenderId: "574806404209",
  appId: "1:574806404209:web:c2a7440c35b0ef624a3fd0",
  measurementId: "G-FKF1B944LE"
};
*/

const firebaseConfig = {
  apiKey: "AIzaSyC_ahs4TQO82J7jKErdLPrgSbS-DNDrD_M",
  authDomain: "sw-mailer.firebaseapp.com",
  projectId: "sw-mailer",
  storageBucket: "sw-mailer.appspot.com",
  messagingSenderId: "1047054763355",
  appId: "1:1047054763355:web:3c53660216a0fe9feb867e",
  measurementId: "G-Y7M9TLJWS2"
};



const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore();

const provider = new GoogleAuthProvider();



export {db};