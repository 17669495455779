import React, { useState, useEffect, useRef } from 'react';
import './Mailer.css';
import { doc, onSnapshot, collection, query, where, getDocs, orderBy, deleteDoc, addDoc, updateDoc, getDoc, setDoc, limit, arrayUnion } from "firebase/firestore";
import { db } from './FirebaseInit';
import { Avatar, Box, Checkbox, CircularProgress, ClickAwayListener, InputAdornment, LinearProgress, OutlinedInput, TextField, Tooltip, Typography } from '@mui/material';
import Switch from 'react-switch';
import { ArrowBack, BackupRounded, BarChartRounded, BorderBottom, CalculateOutlined, CalculateRounded, Check, CheckBox, CheckBoxOutlineBlankRounded, CheckBoxRounded, CheckCircle, CheckCircleRounded, CheckRounded, ChevronRightRounded, Close, CloseRounded, Delete, DeleteRounded, EditRounded, IndeterminateCheckBoxRounded, InfoOutlined, KeyboardArrowDownRounded, PauseRounded, PlayArrow, PlayArrowRounded, RefreshRounded, Save, ScheduleSendRounded, SendRounded, SettingsRounded, Share, StopRounded, X } from '@mui/icons-material';
import ReactModal from 'react-modal';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import 'chartjs-adapter-moment';
import Autocomplete from '@mui/material/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';


function Mailer({uid, activeTab, setActiveTab}) {
  const [showBottomMessage, setShowBottomMessage] = useState(false)
  const [bottomMessage, setBottomMessage] = useState('')
  const [drawerView, setDrawerView] = useState('false')

  const [allUsers, setAllUsers] = useState([])
  const [allUsersObject, setAllUsersObject] = useState([])
  const [allUsersInitialsArray, setAllUsersInitialsArray] = useState([])
  const [userType, setUserType] = useState('');
  const [selectedLeftTab, setSelectedLeftTab] = useState('mailings')
  const [mailings, setMailings] = useState([])
  const [filteredMailings, setFilteredMailings] = useState([])
  const [selectedFilter, setSelectedFilter] = useState('')
  const [processedCount, setProcessedCount] = useState(0)
  const [selectingCount, setSelectingCount] = useState(0)
  const [runningCount, setRunningCount] = useState(0)
  const [scheduledCount, setScheduledCount] = useState(0)
  const [pausedCount, setPausedCount] = useState(0)
  const [canceledCount, setCanceledCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0);
  const [refreshingMailings, setRefreshingMailings] = useState(false)
  const [onlyShowMyMailings, setOnlyShowMyMailings] = useState(true)
  const [initials, setInitials] = useState('')
  const [showFilters, setShowFilters] = useState(true)
  const [filterAccount, setFilterAccount] = useState({ name: "Show All", id: 'show_all' })
  const [filterMailingSpecialist, setFilterMailingSpecialist] = useState()
  const [filterResultsLimit, setFilterResultsLimit] = useState('75')
  const [filterMailID, setFilterMailID] = useState('')
  const [selectedMailIDs, setSelectedMailIDs] = useState([])
  const [selectedMailings, setSelectedMailings] = useState([])
  const [showResendMailingPopup, setShowResendMailingPopup] = useState(false)
  const [calculateListRangesResponse, setCalculateListRangesResponse] = useState([])
  const [changeListForMailID, setChangeListForMailID] = useState('')
  const [calculatingListRangeProgress, setCalculatingListRangeProgress] = useState(0);
  const [calculatingListRangesLoading, setCalculatingListRangesLoading] = useState(false);
  const [changeListRangeForMailID, setChangeListRangeForMailID] = useState('')
  const [changeListRangeStartValue, setChangeListRangeStartValue] = useState(0)
  const [changeListRangeEndValue, setChangeListRangeEndValue] = useState(0)
  const [individualMailIDLoading, setIndividualMailIDLoading] = useState('')
  const [changeStartDateForMailID, setChangeStartDateForMailID] = useState('')
  const [changeStartDateValue, setChangeStartDateValue] = useState('')
  const [showResendConfirmation, setShowResendConfirmation] = useState(false)

  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;

  const [filterStartDate, setFilterStartDate] = useState(formattedDate);
  const [filterEndDate, setFilterEndDate] = useState(formattedDate)

  const [finalSesAccounts, setFinalSesAccounts] = useState([])
  const [showSharingOptions, setShowSharingOptions] = useState(false)
  const [selectedAccountUsers, setSelectedAccountUsers] = useState([])
  const [selectedAccountID, setSelectedAccountID] = useState('')
  const [selectedAccount, setSelectedAccount] = useState([])
  const [showSendsChart, setShowSendsChart] = useState(false)
  const [chartData, setChartData] = useState();
  const [percentagePlugin, setPercentagePlugin] = useState();
  const [selectedAccountName, setSelectedAccountName] = useState('');

  const [showSeedTestSettings, setShowSeedTestSettings] = useState(false)
  const [selectedUserAccount, setSelectedUserAccount] = useState('');
  const [lists, setLists] = useState([])
  const [selectedSesAccount, setSelectedSesAccount] = useState('');
  const [selectedList, setSelectedList] = useState('');

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [deleteMailingInfo, setDeleteMailingInfo] = useState()

  const [autopauses, setAutopauses] = useState([])

  const [creativity, setCreativity] = useState("0.7");
  const [selectedAIModel, setSelectedAIModel] = useState('')
  const [autopilot, setAutopilot] = useState(true);
  const [MJMLChangeCount, setMJMLChangeCount] = useState(0);
  const [MJMLRestructureCount, setMJMLRestructureCount] = useState(0)
  const [HTMLRestructureCount, setHTMLRestructureCount] = useState(0)
  const [HTMLMoreRobustCount, setHTMLMoreRobustCount] = useState(0)
  const [autoApplyAutoFix, setAutoApplyAutoFix] = useState(false)
  const [autoInsertTemplatingVariables, setAutoInsertTemplatingVariables] = useState(false)
  const [autoInsertWeatherConvo, setAutoInsertWeatherConvo] = useState(false)
  const [quotaIncreasePercent, setQuotaIncreasePercent] = useState('');
  const [maxQuota, setMaxQuota] = useState('')
  const [incrementMailingHours, setIncrementMailingHours] = useState(0)
  const [incrementMailingMinutes, setIncrementMailingMinutes] = useState(0)
  const [incrementMailingRandomness, setIncrementMailingRandomness] = useState(0)
  const [increaseSpeedPercent, setIncreaseSpeedPercent] = useState(0)
  const [maxSpeed, setMaxSpeed] = useState(0)

  const serverURL = 'http://api.automail.sendwell.com'
  //const serverURL = 'https://stevenbrekalo.com:3000'
  //const serverURL = 'http://localhost:4000'


  
  // cancel_processed
  // sched
  // selecting
  // running
  // processed

  // MAILINGS

  async function getInitials () {
    const userRef = doc(db, "users", uid);
    const userSnap = await getDoc(userRef);
    setInitials(userSnap.data().initials)
    setUserType(userSnap.data().type)
    if (userSnap.data().type == "mailer" || userSnap.data().type == "franchise") {
      setFilterMailingSpecialist(userSnap.data())
    } else {
      setFilterMailingSpecialist({id: 'show_all', firstName: "Show", lastName: "All"})
    }
  }
  
  const [initialsLoaded, setInitialsLoaded] = useState(false);
  
  useEffect(() => {
    async function initialize() {
      await getInitials();
      setInitialsLoaded(true);
    }
  
    initialize();
  
  }, []);
  
  useEffect(() => {
    if (initialsLoaded) { // Only start the interval if initials are loaded
      getMailings(initials); 
      const interval = setInterval(async () => {
        getMailings(initials); 
      }, 15000); 
  
      // Clean up the interval when the component unmounts
      return () => clearInterval(interval);
    }
  }, [initialsLoaded, selectedFilter, onlyShowMyMailings, initials, filterAccount, filterMailingSpecialist, filterResultsLimit, filterMailID]);
  

  

  async function getMailings() {
    try {
      setRefreshingMailings(true);
      
      const apiUrl = `http://199.16.189.237/mwZycLJElPYC/api.php?limit=50&desiredData=customQuery&customQuery=
      SELECT mailing.mail_id, mailing_name, mailing.bodyfile, 
      list_id, max(mailing.total) as total, 
      sum(sched.success) as success, sum(sched.failure) as failure, max(sched.deferral) as deferral,
      sched.starttime, sched.sched_id, 
      sched.endtime, 
      sched.state as state, 
      mailing.ip_group_list,
      mailing.clicked, mailing.viewed, mailing.scomp as scomp_total, unsubscribed, mailing.bounced, 
      mailing.master_id, mailing.replied, 
      ip_groups.description, 
      master_lists.table_name 
      FROM sched, mailing, ip_groups, master_lists 
      WHERE 
      ${selectedFilter !== "" && selectedFilter !== "seedTests" ? `sched.state = '${selectedFilter}' AND ` : ""}
      ${selectedFilter == "seedTests" ? "sched.total = 1 AND " : "sched.total != 1 AND "} 
      ${filterMailID != '' ? `sched.mail_id = '${filterMailID}' AND ` : ""} 
      ${filterMailingSpecialist.id !== 'show_all' ? `BINARY mailing.bodyfile LIKE '${filterMailingSpecialist.initials}%' AND ` : ""} 
      ${filterAccount.id !== 'show_all' ? `ip_groups.description = '${filterAccount.name}' AND ` : ""} 

      master_lists.master_id = mailing.master_id AND 
      sched.mail_id = mailing.mail_id AND 
      mailing.ip_group_list = ip_groups.ip_group_id 
      GROUP BY mailing.mail_id 
      ORDER BY 
      mailing.mail_id DESC 
      LIMIT ${filterResultsLimit}`;
      
        
        
      //console.log(apiUrl)
      const response = await axios.post(`${serverURL}/api/proxy`, {type: "get", apiURL: apiUrl});
      //console.log(response.data.data)
      setMailings(response.data.data);
      setFilteredMailings(response?.data?.data || [])
      
      setRefreshingMailings(false);

    } catch (error) {
        console.error('Error fetching data:', error);
    }
  }


  async function getMailingCountByStatus (status) {
    try {
      const apiUrl = `http://199.16.189.237/mwZycLJElPYC/api.php?limit=50&desiredData=customQuery&customQuery=
      SELECT COUNT(*) AS count
      FROM sched, mailing, ip_groups, master_lists 
      WHERE 
      ${selectedFilter == "seedTests" ? "sched.total = 1 AND " : "sched.total != 1 AND "} 
      ${filterMailingSpecialist.id !== 'show_all' ? `mailing.bodyfile LIKE '${filterMailingSpecialist.initials}%' AND ` : ""} 
      ${filterAccount.id !== 'show_all' ? `ip_groups.description = '${filterAccount.name}' AND ` : ""} 
      ${status !== '' ? ` state = '${status}' AND ` : ""}
      master_lists.master_id = mailing.master_id AND 
      sched.mail_id = mailing.mail_id AND 
      mailing.ip_group_list = ip_groups.ip_group_id`;      
      
      const response = await axios.post(`${serverURL}/api/proxy`, {type: "get", apiURL: apiUrl});

      //const response = await axios.get(apiUrl);

      console.log(response.data)
      return response.data.data[0].count
    } catch (error) {
      console.log(error)
    }
  }

  useEffect (() => {    
    getCounts()
  }, [initials, filterAccount, filterMailingSpecialist])

  async function getCounts () {
    Promise.all([
      getMailingCountByStatus('processed'),
      getMailingCountByStatus('selecting'),
      getMailingCountByStatus('running'),
      getMailingCountByStatus('sched'),
      getMailingCountByStatus('paused'),
      getMailingCountByStatus('cancel_processed'),
      getMailingCountByStatus('')
    ]).then(results => {
      const [
          processedCount,
          selectingCount,
          runningCount,
          schedCount,
          pausedCount,
          cancelProcessedCount,
          totalCount
      ] = results;
  
      setProcessedCount(processedCount);
      setSelectingCount(selectingCount);
      setRunningCount(runningCount);
      setScheduledCount(schedCount);
      setPausedCount(pausedCount);
      setCanceledCount(cancelProcessedCount);
      setTotalCount(totalCount);
    }).catch(error => {
        // Handle error
        console.error("Error:", error);
    });
  
  }



  const alphabet = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

  const hexCodesArray = [
    '#1caa8c', '#7fd76e', '#a100b4', '#9a0341', '#aefa63', 
    '#46e93b', '#a06621', '#ad1317', '#cd8e0d', '#b41b87', 
    '#c04086', '#c56920', '#2c98be', '#67a78b', '#51d434', 
    '#1755d6', '#af8f92', '#408d26', '#3c8bf9', '#561d75', 
    '#081d77', '#5a22f8', '#322d27', '#9bbea2', '#557062', 
    '#72eaac'
  ]

  function letterToNumber(letter) {
    // Convert the letter to uppercase to handle both cases
    const uppercaseLetter = letter.toUpperCase();
    // Get the ASCII code of the uppercase letter
    const asciiCode = uppercaseLetter.charCodeAt(0);
    // Calculate the position of the letter in the alphabet
    // ASCII code for 'A' is 65, so subtracting 64 gives the position of 'A'
    return asciiCode - 64;
  }



  function formatDate(dateString) {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  }

  function formatTime(dateString) {
    const options = { hour: 'numeric', minute: '2-digit', hour12: true };
    return new Date(dateString).toLocaleTimeString('en-US', options);
  }





  async function getAllUsers() {
    console.log('getting all users');
  
    const q = query(
      collection(db, "users"),
      orderBy("firstName", "asc"),
    );
  
    try {
      const querySnapshot = await getDocs(q);
  
      const allUsersObjectArray = [];
      const allUsersArray = [];
      const allUsersInitialsArray = [];
      querySnapshot.forEach((doc) => {
        allUsersObjectArray.push(doc.data())
        allUsersArray[doc.id] = {
          id: doc.id,
          firstName: doc.data().firstName,
          lastName: doc.data().lastName,
          initials: doc.data().initials,
          email: doc.data().email,
          uid: doc.data().uid,
          userCreatedAt: doc.data().userCreatedAt,
          hexCode: doc.data().hexCode,
          seedTestIPGroup: doc.data().seedTestIPGroup,
          seedTestList: doc.data().seedTestList
        };
        allUsersInitialsArray[doc.data().initials] = {
          firstName: doc.data().firstName,
          lastName: doc.data().lastName,
          initials: doc.data().initials,
          uid: doc.data().uid,
          hexCode: doc.data().hexCode,
        };
      });
      setAllUsers(allUsersArray);
      setAllUsersObject(allUsersObjectArray)
      setAllUsersInitialsArray(allUsersInitialsArray)
    } catch (error) {
      console.error("Error getting documents: ", error);
    }
  }




  // SES ACCOUNTS

  async function getAllAccountsFromFirestore() {
    const querySnapshot = await getDocs(collection(db, "sesAccounts"));
    const accounts = {};
    querySnapshot.forEach((doc) => {
        accounts[doc.id] = doc.data();
    });
    return accounts;
}

async function getAccounts() {
    try {
        // Fetch all accounts from Firestore
        const firestoreAccounts = await getAllAccountsFromFirestore();

        const response = await axios.post(`${serverURL}/api/proxy`, {type: "get", apiURL: 'http://199.16.189.237/mwZycLJElPYC/api.php?desiredData=getAccounts&limit=500'});


        // Fetch accounts from Robo
        const roboAccounts = response.data.data;

        // Match up the data
        const finalData = roboAccounts?.map(account => {
            const firestoreAccount = firestoreAccounts[account.ip_group_id];
            if (firestoreAccount) {
                // Account found in Firestore
                return {
                    id: firestoreAccount.id,
                    lastSendRangeStart: firestoreAccount.lastSendRangeStart,
                    lastSendRangeEnd: firestoreAccount.lastSendRangeEnd,
                    lastSendTimestamp: firestoreAccount.lastSendTimestamp,
                    status: 'Linked',
                    name: firestoreAccount.name,
                    users: firestoreAccount.users,
                    domain: firestoreAccount.domain,
                    domainDaysUsed: firestoreAccount.domainDaysUsed,
                };
            } else {
                // Account not found in Firestore
                return {
                    id: account.ip_group_id,
                    lastSendRangeStart: 0,
                    lastSendRangeEnd: 0,
                    lastSendTimestamp: '',
                    status: "Not Linked",
                    name: account.description,
                    users: []
                };
            }
        });

        // Find Firestore accounts not found in API endpoint
        for (const accountId in firestoreAccounts) {
          if (!roboAccounts.find(account => account.ip_group_id === accountId)) {
              finalData.push({
                  id: accountId,
                  lastSendRangeStart: firestoreAccounts[accountId].lastSendRangeStart,
                  lastSendRangeEnd: firestoreAccounts[accountId].lastSendRangeEnd,
                  lastSendTimestamp: firestoreAccounts[accountId].lastSendTimestamp,
                  status: 'DELETED',
                  name: firestoreAccounts[accountId].name,
                  users: firestoreAccounts[accountId].users
              });
          }
        }

        // Set final data
        setFinalSesAccounts(finalData);

    } catch (error) {
        console.error("Error getting accounts:", error);
    }
}






  useEffect(() => {
    getAllUsers();
    getAccounts();
  }, [])



  async function adjustSharing(userID) {
    let updatedUIDs;
  
    if (selectedAccountUsers.includes(userID)) {
      updatedUIDs = selectedAccountUsers.filter(item => item !== userID);
    } else {
      updatedUIDs = [...selectedAccountUsers, userID];
    }
  
    setSelectedAccountUsers(updatedUIDs);
  }




  async function saveAccount () {
      //SAVING SES ACCOUNT TO FIRESTORE
      const accountRef = doc(db, "sesAccounts", selectedAccountID);
      const accountSnap = await getDoc(accountRef);
      let domainDaysUsed = 0;
      let domain = '';
      if (accountSnap.exists()) {
        domainDaysUsed = accountSnap.data()?.domainDaysUsed || 0;
        domain = accountSnap.data()?.domain || '';
      } else {
        domainDaysUsed = 0;
        domain = '';
      }

      await setDoc(doc(db, "sesAccounts", selectedAccountID), {
        id: selectedAccount.id,
        lastSendRangeStart: 0,
        lastSendRangeEnd: 45000,
        lastSendTimestamp: '',
        name: selectedAccount.name,
        users: selectedAccountUsers,
        domain: domain,
        domainDaysUsed: domainDaysUsed,
      });
      console.log("SES Account saved to firestore with ID: ", selectedAccountID);

      getAccounts()
  }








  const options = {
    plugins: {
      legend: {
        display: false, // Assuming you don't want to display a legend
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        titleFont: {
          size: 0,
        },
        bodyFont: {
          family: 'Arial',
          size: 12,
        },
        titleColor: 'rgba(0, 0, 0, 1)', // Set the text color to black
        titleAlign: 'center',
        bodyColor: 'rgba(0, 0, 0, 1)', // Set the text color to black
        borderColor: '#e7e7e7',
        borderWidth: 1,
        boxPadding: 5,
        caretPadding: 20,
        padding: 5,
        caretSize: 0,
        bodySpacing: 0,
        usePointStyle: true
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
            unit: 'day'
        },
        grid: {
          display: false, // Hides the grid lines for the X-axis
          drawBorder: false, // Hides the axis border
        },
        ticks: {
          color: 'black', // Set the color of the axis labels
          // Additional styling can be done here
        },
      },
      y: {
        beginAtZero: true, // Ensure the scale starts at 0
        //max: suggestedMax, // Set the calculated maximum value
        grid: {
          color: 'rgba(0, 0, 0, 0.1)', // Lightens the grid lines for the Y-axis
          drawBorder: false, // Hides the axis border
        },
        ticks: {
          color: 'black', // Set the color of the axis labels
          // Additional styling can be done here
          callback: function(value, index, values) {
            // This callback formats the Y-axis labels with a dollar sign
            return value / 1000 + "K";
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false, // Set to false to control the chart's height independently
  };
  
  
  




  async function getPastMailingsByAccount(selectedAccount) {
    const response = await axios.post(`${serverURL}/api/proxy`, {type: "get", apiURL: `http://199.16.189.237/mwZycLJElPYC/api.php?desiredData=getMailingsByAccount&accountID=${selectedAccount}`});

    console.log("PAST MAILINGS BY ACCOUNT", response.data.data);
    const sumPerDay = {};
  
    for (let i = 0; i < response.data.data.length; i++) {
      const item = response.data.data[i];
      if (item.state !== 'cancel_processed') {
        const starttime = new Date(item.starttime);
        const dateKey = starttime.toISOString().split('T')[0];
        if (!sumPerDay[dateKey]) {
          sumPerDay[dateKey] = { total: 0, lastSendTime: starttime };
        }
        sumPerDay[dateKey].total += parseInt(item.total);
      }
    }
  
    const sumPerDayObject = [];
    for (const key in sumPerDay) {
      sumPerDayObject.push({ x: key, y: sumPerDay[key].total });
    }
  
    console.log(sumPerDayObject);
  
    const data = sumPerDayObject.map((dataPoint, index) => {
      const previousTotal = index > 0 ? sumPerDayObject[index - 1].y : 0;
      const percentageDifference = index > 0 ? ((dataPoint.y - previousTotal) / previousTotal) * -100 : 0;
  
      return {
        x: dataPoint.x,
        y: dataPoint.y,
        percentageDifference: percentageDifference.toFixed(2) // Limit to 2 decimal places
      };
    });
  
    const reversedDates = data.map(dataPoint => dataPoint.x).reverse();
    const reversedTotals = data.map(dataPoint => dataPoint.y).reverse();
    const reversedPercentages = data.map(dataPoint => dataPoint.percentageDifference).reverse();
  
    setChartData({
      labels: reversedDates, // Reversed array of dates
      datasets: [
        {
          label: 'Total',
          data: reversedTotals,
          fill: true,
          backgroundColor: 'rgb(43, 95, 226)',
        },
      ]
    });
  


  }
  
  



  async function stopMailing (type, schedID, mailID) {
    try {
      if (type == 'cancel') {
        const response = await axios.post(`${serverURL}/api/proxy`, {type: "get", apiURL: `http://199.16.189.237/mwZycLJElPYC/api.php?desiredData=stopMailing&cancel_now=1&sched_id=${schedID}`});
        setShowBottomMessage(true)
        setBottomMessage("Mailing canceled, please wait a minute for the view to update")
      } else if (type == 'pause') {
        const response = await axios.post(`${serverURL}/api/proxy`, {type: "get", apiURL: `http://199.16.189.237/mwZycLJElPYC/api.php?desiredData=stopMailing&pause_now=1&sched_id=${schedID}`});
        setShowBottomMessage(true)
        setBottomMessage("Mailing paused, please wait a minute for the view to update")
      } else if (type == 'resume') {
        const response = await axios.post(`${serverURL}/api/proxy`, {type: "get", apiURL: `http://199.16.189.237/mwZycLJElPYC/api.php?desiredData=stopMailing&resume_now=1&sched_id=${schedID}`});
        setShowBottomMessage(true)
        setBottomMessage("Mailing resumed, please wait a minute for the view to update")
      } else if (type == 'delete') {
        const response = await axios.post(`${serverURL}/api/proxy`, {type: "get", apiURL: `http://199.16.189.237/mwZycLJElPYC/api.php?desiredData=stopMailing&del_now=1&sched_id=${schedID}&mailID=${mailID}`});
        setShowBottomMessage(true)
        setBottomMessage("Mailing deleted, please wait a minute for the view to update")
        setShowDeleteConfirmation(false)
      }
    } catch (error) {
      console.log('error stopping mailing', error)
      setShowBottomMessage(true)
      setBottomMessage("ERROR")
    }



  }





  async function getLists () {
    try {
      const response = await axios.post(`${serverURL}/api/proxy`, {type: "get", apiURL: `http://199.16.189.237/mwZycLJElPYC/api.php?limit=500&desiredData=getLists`});
      console.log(response.data.data)
      setLists(response?.data?.data || [])

    } catch (error) {
      console.log("ERROR GETTING LISTS ", error)
    }
  }

  useEffect(() => {
    getLists();
  }, [])






  async function saveSeedTestSettings (uid) {
    const userRef = doc(db, "users", uid);

    const selectedSesAccountID = selectedSesAccount.split("---")[0];
    const selectedSesAccountName = selectedSesAccount.split("---")[1];
    const selectedSesListID = selectedList.split("---")[0];
    const selectedSesListName = selectedList.split("---")[1];


    await updateDoc(userRef, {
      seedTestIPGroupID: selectedSesAccountID,
      seedTestIPGroupName: selectedSesAccountName,
      seedTestListID: selectedSesListID,
      seedTestListName: selectedSesListName,
    });

    getAllUsers();
    setShowSeedTestSettings(false);
    setSelectedSesAccount('');
    setSelectedList('');
  }





  const sesAccountsForFilter = [{ name: "Show All", id: 'show_all' }, ...finalSesAccounts];
  const allUsersObjectForFilter = [{ firstName: "Show", lastName: "All", id: 'show_all' }, ...allUsersObject];









  useEffect (() => {
    if (selectedLeftTab == 'autopauses') {
      getAutopauses()
    }
  }, [selectedLeftTab])


  async function getAutopauses () {
    const autopausesArray = [];
    
    // Get the current timestamp
    const currentTimestamp = new Date();
    
    // Calculate the timestamp of 2 days ago
    const twoDaysAgo = new Date(currentTimestamp);
    twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
    
    // Create a Firestore query with orderBy and a where clause
    const q = query(
      collection(db, "autoPauses"),
      orderBy('lastEditTimestamp', 'desc'),
      where('lastEditTimestamp', '>=', twoDaysAgo)
    );
  
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      autopausesArray.push(doc.data());
    });
    
    setAutopauses(autopausesArray);
  }


 

  useEffect(() => {
    async function getQuotaSettings () {
      const quotaSettingsRef = doc(db, "settings", "quota");
      const docSnap = await getDoc(quotaSettingsRef);
      if (docSnap.exists()) {
        setMaxQuota(docSnap.data().maxQuota);
        setQuotaIncreasePercent(docSnap.data().quotaIncreasePercent)
        setIncrementMailingHours(docSnap.data().incrementMailingHours)
        setIncrementMailingMinutes(docSnap.data().incrementMailingMinutes)
        setIncrementMailingRandomness(docSnap.data().incrementMailingRandomness)
        setIncreaseSpeedPercent(docSnap.data().increaseSpeedPercent)
        setMaxSpeed(docSnap.data().maxSpeed)
      } else {
        console.log('Quota Settings Document Not Found');
      }
    }
    async function getCreativesSettings () {
      const creativesSettingsRef = doc(db, "settings", "creatives");
      const docSnap = await getDoc(creativesSettingsRef);
      if (docSnap.exists()) {
        setCreativity(docSnap.data().creativity);
        setSelectedAIModel(docSnap.data().selectedAIModel);
        setAutopilot(docSnap.data().autopilot);
        setMJMLChangeCount(docSnap.data().MJMLChangeCount);
        setMJMLRestructureCount(docSnap.data().MJMLRestructureCount);
        setHTMLRestructureCount(docSnap.data().HTMLRestructureCount);
        setHTMLMoreRobustCount(docSnap.data().HTMLMoreRobustCount);
        setAutoApplyAutoFix(docSnap.data().autoApplyAutoFix);
        setAutoInsertTemplatingVariables(docSnap.data().autoInsertTemplatingVariables);
        setAutoInsertWeatherConvo(docSnap.data().autoInsertWeatherConvo);
      } else {
        console.log('Creatives Settings Document Not Found');
      }
    }
    getQuotaSettings();
    getCreativesSettings();
  }, [])


  async function saveAdminSettings() {
    const quotaSettingsRef = doc(db, "settings", "quota");
    await updateDoc(quotaSettingsRef, {
      maxQuota: maxQuota * 1,
      quotaIncreasePercent: quotaIncreasePercent * 1,
      incrementMailingHours: incrementMailingHours * 1,
      incrementMailingMinutes: incrementMailingMinutes * 1,
      incrementMailingRandomness: incrementMailingRandomness * 1,
      increaseSpeedPercent: increaseSpeedPercent * 1,
      maxSpeed: maxSpeed * 1,
    });


    const creativesSettingsRef = doc(db, "settings", "creatives");
    await updateDoc(creativesSettingsRef, {
      creativity: creativity,
      selectedAIModel: selectedAIModel,
      autopilot: autopilot,
      MJMLChangeCount: MJMLChangeCount,
      MJMLRestructureCount: MJMLRestructureCount,
      HTMLRestructureCount: HTMLRestructureCount,
      HTMLMoreRobustCount: HTMLMoreRobustCount,
      autoApplyAutoFix: autoApplyAutoFix,
      autoInsertTemplatingVariables: autoInsertTemplatingVariables,
      autoInsertWeatherConvo: autoInsertWeatherConvo,
    });
    setShowBottomMessage(true)
    setBottomMessage("Settings Saved")

  }








  function adjustSelection(mailID) {
    let updatedMailIDs;
    console.log(selectedMailIDs)
    console.log(mailID)

    if (selectedMailIDs.includes(mailID)) {
      console.log('includes')
      updatedMailIDs = selectedMailIDs.filter(item => item !== mailID.toString());
      setCalculateListRangesResponse(prevState => {
        const { [mailID]: removedMailId, ...rest } = prevState;
        return rest;
      });
      
    } else {
      console.log('does not include')
      updatedMailIDs = [...selectedMailIDs, mailID];
    }
    console.log(updatedMailIDs)
    setSelectedMailIDs(updatedMailIDs);
  }




  
  function addMailingObject () {
    const selectedMailingsArray = [];
    for (let i = 0; i < selectedMailIDs.length; i++) {
      for (let j = 0; j < filteredMailings.length; j++) {
        if (filteredMailings[j].mail_id == selectedMailIDs[i]) {
          selectedMailingsArray.push(filteredMailings[j])
        }
      }
    }
    setSelectedMailings(selectedMailingsArray)
  }

  useEffect(() => {
    addMailingObject()
  }, [selectedMailIDs])





  async function calculateListRangesForResends(mailID, overrideMailingList, overrideMailingListID, individual) {
    try {
      setCalculatingListRangeProgress(0)
      setCalculatingListRangesLoading(true)
      const response = await fetch(`${serverURL}/api/resendMailings?mailIDs=[${mailID}]&overrideMailingList=${overrideMailingList}&overrideMailingListID=${overrideMailingListID}`);
  
      // Check if the response is successful
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      // Process the response as a stream
      const reader = response.body.getReader();
      let decoder = new TextDecoder();
  
      while (true) {
        const { done, value } = await reader.read();
  
        // Check if the stream has ended
        if (done) {
          console.log('Streaming ended');
          setCalculatingListRangesLoading(false)
          setIndividualMailIDLoading('')

          break;
        }
  
        // Convert the received chunk to text and process it
        const chunk = decoder.decode(value, { stream: true });
        console.log("Received chunk:", chunk);

        console.log(JSON.parse(chunk)[mailID]);
        

        let updatedData = calculateListRangesResponse;
        updatedData[mailID] = JSON.parse(chunk)[mailID];
        console.log(updatedData)
        if (individual) {
          setCalculateListRangesResponse(updatedData)
        } else {
          setCalculateListRangesResponse(JSON.parse(chunk))
        }
        setCalculatingListRangeProgress(0)
        // Process the chunk as needed
      }
  
    } catch (error) {
      console.error("Error calculating list ranges for resends:", error);
    }
  }
  
  //console.log(individualMailIDLoading)


  useEffect(() => {
    setCalculatingListRangeProgress(0)
    if (calculatingListRangesLoading) {
      const timer = setInterval(() => {
        const randomNumberBetween7and9 = Math.floor(Math.random() * 3) + 7;
        setCalculatingListRangeProgress((prevProgress) => (prevProgress + randomNumberBetween7and9 >= 100 ? 100 : prevProgress + randomNumberBetween7and9));
      }, 1000); 
      return () => {
        clearInterval(timer);
      };
    }
  }, [calculatingListRangesLoading]);




  async function resendMailingsLoop () {
    for (let i = 0; i < selectedMailIDs.length; i++) {
      await createMailing(calculateListRangesResponse[selectedMailIDs[i]])
    }
  }

  async function createMailing (mailing) {
    try {

      const mailingTimestamp = new Date(mailing.mailScheduleTimestamp)

      const data = {
        mailingName: mailing.mailingName.endsWith("RESEND") ? mailing.mailingName : mailing.mailingName + "RESEND",
        listIDs: `${mailing.listIDs} 1`,
        bodyFile: mailing.bodyFile.slice(0,14),
        queueID: 0,
        ipGroup: mailing.ipGroup,
        startNow: mailing.sendMailingImmediately ? 1 : 0,
        month: mailingTimestamp.getMonth() + 1,
        day: mailingTimestamp.getDate(),
        hour: mailingTimestamp.getHours(),
        minute: mailingTimestamp.getMinutes(),
        year: mailingTimestamp.getFullYear(),
        startCount: mailing.listRangeStart,
        sendTotal: mailing.listRangeEnd - mailing.listRangeStart,
        randomize: mailing.randomize,
        mailingID: mailing.mailID,
        smtpRelayGroupID: mailing.smtpRelayGroupID
      };

      console.log(data)

      const response = await axios.post(`${serverURL}/api/createMailing`, data)

      setCalculateListRangesResponse({})
      setSelectedMailIDs([])
      setSelectedMailings([])

    } catch (error) {
      console.log("ERROR creating mailing mailer.js", error)
    }
  }

  console.log(calculateListRangesResponse)



  useEffect(() => {
    if (showBottomMessage) {
      // Set a timeout to toggle showBottomMessage back to false after 5 seconds
      const timeoutId = setTimeout(() => {
        setShowBottomMessage(false);
      }, 5000);

      // Clean up the timeout when the component unmounts or when showBottomMessage changes
      return () => clearTimeout(timeoutId);
    }
  }, [showBottomMessage]);


  

    return ( 
      <div className='MailerContainer'>
        <div className='MailerLeftContainer'>


            <div className='MailerLeftNavigationContainer'>
              <span className={selectedLeftTab == 'mailings' ? 'MailerLeftItemContainerSelected' : 'MailerLeftItemContainer'} onClick={() => {setSelectedLeftTab('mailings')}}>Mailings</span>
              <span className={selectedLeftTab == 'accounts' ? 'MailerLeftItemContainerSelected' : 'MailerLeftItemContainer'} onClick={() => {setSelectedLeftTab('accounts')}}>Accounts</span>
              <span className={selectedLeftTab == 'autopauses' ? 'MailerLeftItemContainerSelected' : 'MailerLeftItemContainer'} onClick={() => {setSelectedLeftTab('autopauses')}}>Auto Pauses</span>
              {userType == 'admin' ?
                <>
                  <span className={selectedLeftTab == 'seedTests' ? 'MailerLeftItemContainerSelected' : 'MailerLeftItemContainer'} onClick={() => {setSelectedLeftTab('seedTests')}}>Seed Test Settings</span>
                  <span className={selectedLeftTab == 'adminSettings' ? 'MailerLeftItemContainerSelected' : 'MailerLeftItemContainer'} onClick={() => {setSelectedLeftTab('adminSettings')}}>Admin Settings</span>
                </>
              :
                <></>
              }


            </div>

      
          {selectedLeftTab == 'mailings' ? 
          


            <div className='MailerLeftFiltersContainer'>


              {/* 
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className='MailerDatePickerContainer'>
                  <DatePicker
                    sx={{ width: "13vw" }}
                    label="Start Date"
                    value={dayjs(filterStartDate)}
                    onChange={(newValue) => setFilterStartDate(newValue)}
                  />
                </div>
                
                <div className='MailerDatePickerContainer'>
                  <DatePicker
                    sx={{ width: "13vw" }}
                    label="End Date"
                    value={dayjs(filterEndDate)}
                    onChange={(newValue) => setFilterEndDate(newValue)}
                  />
                </div>
              </LocalizationProvider> 
              */}


              <span className='MailerFiltersTitle'>MAILING FILTERS</span>


              {userType == 'admin' || userType == 'mailer' && (
                <>

                  <TextField
                    id="outlined-controlled"
                    label="Mail ID"
                    value={filterMailID}
                    sx={{ width: "11.5vw" }}
                    size="small"
                    onChange={(event) => {
                      setFilterMailID(event.target.value);
                    }}
                  />



                  <div className='ChatGPTCreateMailingAccountSelectorContainer'>
                    <Autocomplete
                      size="small"
                      sx={{ width: "11.5vw" }}
                      options={sesAccountsForFilter}
                      getOptionLabel={(option) => option.name} // Assuming each option is an object with a `name` property
                      onChange={(event, value) => { 
                        console.log("Selected value:", value ? value.name : "");
                        setFilterAccount(value); // Assuming this sets the entire selected object as state
                        }}                      
                      value={filterAccount || null} // Ensure you're using a state variable that holds the selected object
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      renderInput={(params) => (
                        <TextField {...params} label="Account" margin="normal" />
                      )}
                      renderOption={(props, option, { inputValue }) => {
                        const matches = match(option.name, inputValue, { insideWords: true }); // Assuming match and parse functions work as expected
                        const parts = parse(option.name, matches);

                        return (
                          <li {...props}>
                            <div>
                              {parts.map((part, index) => (
                                <span key={index} style={{fontWeight: part.highlight ? 700 : 400,}}>{part.text}</span>
                              ))}
                            </div>
                          </li>
                        );
                      }}
                    />
                  </div>



                  <div className='ChatGPTCreateMailingAccountSelectorContainer'>
                    <Autocomplete
                      size="small"
                      sx={{ width: "11.5vw" }}
                      options={allUsersObjectForFilter}
                      getOptionLabel={(option) => option.firstName + " " + option.lastName} // Assuming each option is an object with a `name` property
                      onChange={(event, value) => { 
                        console.log("Selected value:", value ? value.firstName + " " + value.lastName : "");
                        setFilterMailingSpecialist(value); // Assuming this sets the entire selected object as state
                        }}                      
                      value={filterMailingSpecialist || null} // Ensure you're using a state variable that holds the selected object
                      isOptionEqualToValue={(option, value) => value.firstName + " " + value.lastName === option.firstName + " " + option.lastName}
                      renderInput={(params) => (
                        <TextField {...params} label="Specialists" margin="normal" />
                      )}
                      renderOption={(props, option, { inputValue }) => {
                        const matches = match(option.firstName + " " + option.lastName, inputValue, { insideWords: true }); // Assuming match and parse functions work as expected
                        const parts = parse(option.firstName + " " + option.lastName, matches);

                        return (
                          <li {...props}>
                            <div>
                              {parts.map((part, index) => (
                                <span key={index} style={{fontWeight: part.highlight ? 700 : 400,}}>{part.text}</span>
                              ))}
                            </div>
                          </li>
                        );
                      }}
                    />
                  </div>

                </>
              )}



              <div className='ChatGPTCreateMailingAccountSelectorContainer'>
                <Autocomplete
                  size="small"
                  sx={{ width: "11.5vw" }}
                  options={['75', '100', '250', '500', '1000']}
                  getOptionLabel={(option) => option} // Assuming each option is an object with a `name` property
                  onChange={(event, value) => { 
                    console.log("Selected value:", value ? value : "");
                    setFilterResultsLimit(value); // Assuming this sets the entire selected object as state
                    }}                      
                  value={filterResultsLimit || null} // Ensure you're using a state variable that holds the selected object
                  isOptionEqualToValue={(option, value) => value === option}
                  renderInput={(params) => (
                    <TextField {...params} label="Results" margin="normal" />
                  )}
                  renderOption={(props, option, { inputValue }) => {
                    const matches = match(option, inputValue, { insideWords: true }); // Assuming match and parse functions work as expected
                    const parts = parse(option, matches);

                    return (
                      <li {...props}>
                        <div>
                          {parts.map((part, index) => (
                            <span key={index} style={{fontWeight: part.highlight ? 700 : 400,}}>{part.text}</span>
                          ))}
                        </div>
                      </li>
                    );
                  }}
                />
              </div>

              


              
            </div>

            : <></>
            }



          



        </div>
        <div className='MailerRightContainer'>

          <div className='MailerContentContainer'>

            {selectedLeftTab == 'mailings' ?
              <div>


                <div className='MailerMailingsTableTabs'>

                  <div className={selectedFilter === '' ? 'MailerMailingsTableTabActive' : 'MailerMailingsTableTab'} onClick={() => setSelectedFilter('')}>
                    <span className='MailerMailingsTableTabText' style={{ color: selectedFilter == '' ? "#000" : "#637381" }}>Mailings</span> 
                    <span className='MailerMailingsTableTabAll'>{totalCount}</span>
                  </div>

                  <div className={selectedFilter === 'processed' ? 'MailerMailingsTableTabActive' : 'MailerMailingsTableTab'} onClick={() => setSelectedFilter('processed')}>
                    <span className='MailerMailingsTableTabText' style={{ color: selectedFilter == 'processed' ? "#000" : "#637381" }}>Sent</span> 
                    <span className='MailerMailingsTableTabProcessed' style={{ color: selectedFilter == 'processed' ? "#fff" : "#008000", backgroundColor: selectedFilter == 'processed' ? "#04b904" : "#99e099c9" }}>{processedCount}</span>
                  </div>

                  <div className={selectedFilter === 'running' ? 'MailerMailingsTableTabActive' : 'MailerMailingsTableTab'} onClick={() => {setSelectedFilter('running')}}>
                    <span className='MailerMailingsTableTabText' style={{ color: selectedFilter == 'running' ? "#000" : "#637381" }}>Sending</span> 
                    <span className='MailerMailingsTableTabSending' style={{ color: selectedFilter == 'running' ? "#fff" : "#e99701", backgroundColor: selectedFilter == 'running' ? "#e99701" : "#fee3c8c9" }}>{runningCount}</span>
                  </div>

                  <div className={selectedFilter === 'selecting' ? 'MailerMailingsTableTabActive' : 'MailerMailingsTableTab'} onClick={() => {setSelectedFilter('selecting')}}>
                    <span className='MailerMailingsTableTabText' style={{ color: selectedFilter == 'selecting' ? "#000" : "#637381" }}>Generating</span> 
                    <span className='MailerMailingsTableTabGenerating' style={{ color: selectedFilter == 'selecting' ? "#fff" : "#024fa7", backgroundColor: selectedFilter == 'selecting' ? "#024fa7" : "#c8defec9" }}>{selectingCount}</span>
                  </div>

                  <div className={selectedFilter === 'paused' ? 'MailerMailingsTableTabActive' : 'MailerMailingsTableTab'} onClick={() => {setSelectedFilter('paused')}}>
                    <span className='MailerMailingsTableTabText' style={{ color: selectedFilter == 'paused' ? "#000" : "#637381" }}>Paused</span> 
                    <span className='MailerMailingsTableTabPaused' style={{ color: selectedFilter == 'paused' ? "#fff" : "#E95D01", backgroundColor: selectedFilter == 'paused' ? "#E95D01" : "#fedbc8c9" }}>{pausedCount}</span>
                  </div>

                  <div className={selectedFilter === 'cancel_processed' ? 'MailerMailingsTableTabActive' : 'MailerMailingsTableTab'} onClick={() => {setSelectedFilter('cancel_processed')}}>
                    <span className='MailerMailingsTableTabText' style={{ color: selectedFilter == 'cancel_processed' ? "#000" : "#637381" }}>Canceled</span> 
                    <span className='MailerMailingsTableTabCanceled' style={{ color: selectedFilter == 'cancel_processed' ? "#fff" : "#ce2222", backgroundColor: selectedFilter == 'cancel_processed' ? "#ce2222" : "#fcd3d3c9" }}>{canceledCount}</span>
                  </div>

                  <div className={selectedFilter === 'sched' ? 'MailerMailingsTableTabActive' : 'MailerMailingsTableTab'} onClick={() => {setSelectedFilter('sched')}}>
                    <span className='MailerMailingsTableTabText' style={{ color: selectedFilter == 'sched' ? "#000" : "#637381" }}>Scheduled</span> 
                    <span className='MailerMailingsTableTabScheduled' style={{ color: selectedFilter == 'sched' ? "#fff" : "#888", backgroundColor: selectedFilter == 'sched' ? "#888" : "#e5e5e5c9" }}>{scheduledCount}</span>
                  </div>

                  {/*
                  <div className={selectedFilter === 'seedTests' ? 'MailerMailingsTableTabActive' : 'MailerMailingsTableTab'} onClick={() => {setSelectedFilter('seedTests')}}>
                    <span className='MailerMailingsTableTabText' style={{ color: selectedFilter == 'seedTests' ? "#000" : "#637381" }}>Seed Tests</span> 
                  </div>
                  */}

                  {/*
                  <div className={selectedFilter === onlyShowMyMailings ? 'MailerMailingsTableTabActive' : 'MailerMailingsTableTab'} onClick={() => {setOnlyShowMyMailings(!onlyShowMyMailings)}}>
                    <span className='MailerMailingsTableTabText'>{onlyShowMyMailings ? "Show Everyone" : "Only Show Mine"}</span> 
                  </div>
                  */}

                  <div className='MailerMailingsTableTabIcon' onClick={() => {getMailings(initials); getCounts()}}>
                    <span className={refreshingMailings ? 'MailerMailingsTableTabIconSpinning' : 'MailerMailingsTableTabIconContainer'}><RefreshRounded/></span> 
                  </div>

                </div>


                

                <table className='MailerMailingsTable'>

                  <tbody>
                    {selectedMailIDs.length > 0 ?

                      <tr className='MailerMailingsTableSelectedRow'>
                        <td>
                          <div className='MailerSelectedMailingCheckboxContainer' onClick={() => {setSelectedMailIDs([]); setCalculateListRangesResponse([])}}>
                            <div className='MailerSelectedMailingCheckboxChecked'>
                              <CheckRounded style={{width: 15}}/>
                            </div>
                          </div>
                        </td>
                        <td colSpan={8}>
                          <div>
                            <span>{selectedMailIDs.length} selected</span>
                          </div>
                        </td> 
                        <td colSpan={3}>
                          <div className='MailerSelectedMailingResendButtonContainer'> 
                            <div className='MailerSelectedMailingResendButton' onClick={() => {setShowResendMailingPopup(true)}}>
                              <span className='MailerSelectedMailingResendButtonText'>Resend</span>
                              <ScheduleSendRounded/>                            
                            </div>
                          </div>
                        </td>
                      </tr>

                      :
                      
                      <tr style={{fontWeight: 600}}>
                        <td>
                          <div className='MailerSelectedMailingCheckboxContainer'>
                            <div className='MailerSelectedMailingCheckbox'>
                              <CheckRounded style={{width: 15}}/>
                            </div>
                          </div>
                        </td>
                        <td>
                        </td>
                        <td>
                          <div className='MailerMailingColumn1'>
                            <span className='MailerMailingRowMailingName'>Mailing Name</span>
                            <span className='MailerMailingRowDescription'>SES Account</span>
                          </div>
                        </td>
                        <td>
                          <div className='MailerMailingColumn2'>
                            <span className='MailerMailingRowMailingStartDate'>Start Date</span>
                            <span className='MailerMailingRowMailingStartTime'>Start Time</span>
                          </div>
                        </td>
                        <td>
                          <div className='MailerMailingColumn2'>
                            <span className='MailerMailingRowMailingStartDate'>End Date</span>
                            <span className='MailerMailingRowMailingStartTime'>End Time</span>
                          </div>
                        </td>
                        <td><span className='MailerMailingRowBodyFile'>Creative</span></td>
                        <td>
                          <span className='MailerMailingRowViewed'>View %</span>
                          <span className='MailerMailingRowClicked'>Click %</span>
                        </td>
                        <td>
                          <span className='MailerMailingRowSuccess'>Success</span>
                        </td>
                        <td>
                          <span className='MailerMailingRowSuccess'>Deferral</span>
                        </td>
                        <td>
                          <span className='MailerMailingRowSuccess'>Total</span>
                        </td>
                        <td></td>
                        <td></td>
                        
                      </tr>
                    }
                    {filteredMailings?.map((x) => { return (
                      <tr style={{backgroundColor: (x.deferral * 1) > (x.success / 100) ? "#fcd3d3c9" : 'white'}}>
                        <td>
                          {selectedMailIDs.includes(x.mail_id) ?
                            <div className='MailerSelectedMailingCheckboxContainer' onClick={() => {adjustSelection(x.mail_id)}}>
                              <div className='MailerSelectedMailingCheckboxChecked'>
                                <CheckRounded style={{width: 15}}/>
                              </div>
                            </div>
                          :
                            <div className='MailerSelectedMailingCheckboxContainer' onClick={() => {adjustSelection(x.mail_id)}}>
                              <div className='MailerSelectedMailingCheckbox'>
                                <CheckRounded style={{width: 15}}/>
                              </div>
                            </div>
                          }
                        </td>
                        <td style={{paddingLeft: 10}}>
                          <Avatar sx={{ bgcolor: allUsersInitialsArray[(x?.bodyfile)?.slice(0,2)]?.hexCode, fontSize: 18 }}>{(x.bodyfile)?.slice(0,2)}</Avatar>
                        </td>
                        <td>
                          <div className='MailerMailingColumn1'>
                            <span className='MailerMailingRowMailingName'>{(x.mail_id)} {x.mailing_name}</span>
                            <span className='MailerMailingRowDescription'>{x.description}</span>
                          </div>
                        </td>
                        <td>
                          <div className='MailerMailingColumn2'>
                            <span className='MailerMailingRowMailingStartDate'>{formatDate(x.starttime)}</span>
                            <span className='MailerMailingRowMailingStartTime'>{formatTime(x.starttime)}</span>
                          </div>
                        </td>
                        <td>
                          <div className='MailerMailingColumn2'>
                            <span className='MailerMailingRowMailingStartDate'>{x.endtime != "0000-00-00 00:00:00" ? formatDate(x.endtime) : ''}</span>
                            <span className='MailerMailingRowMailingStartTime'>{x.endtime != "0000-00-00 00:00:00" ? formatTime(x.endtime) : ''}</span>
                          </div>
                        </td>
                        <td><span className='MailerMailingRowBodyFile'>{x.bodyfile}</span></td>
                        <td>
                          <Tooltip title={new Intl.NumberFormat('en-US').format(x.viewed)} placement='top'>
                            <span className='MailerMailingRowViewed'>{x.state != 'sched' && x.state != 'cancel_processed' && x.viewed != 0 ? new Intl.NumberFormat('en-US', { style: 'percent'}).format((x.viewed) / (x.success)) : '0%'}</span>
                          </Tooltip>
                          <Tooltip title={new Intl.NumberFormat('en-US').format(x.clicked)} placement='top'>
                            <span className='MailerMailingRowClicked'>{x.state != 'sched' && x.state != 'cancel_processed' && x.viewed != 0 ? new Intl.NumberFormat('en-US', { style: 'percent'}).format((x.clicked) / (x.success)) : '0%'}</span>
                          </Tooltip>
                        </td>
                        <td>
                          <span className='MailerMailingRowSuccess'>{x.state != 'sched' ? new Intl.NumberFormat('en-US').format(x.success) : '0'}</span>
                        </td>
                        <td>
                          <span className='MailerMailingRowSuccess'>{x.state != 'sched' ? new Intl.NumberFormat('en-US').format(x.deferral) : '0'}</span>
                        </td>
                        <td>
                          <span className='MailerMailingRowSuccess'>{x.state != 'sched' ? new Intl.NumberFormat('en-US').format(x.total) : '0'}</span>
                        </td>
                        <td>
                          {x.state == 'processed' ?
                            <span className='MailerMailingRowStatusProcessed'>Sent</span> : <></>
                          }
                          {x.state == 'selecting' ?
                            <span className='MailerMailingRowStatusSelecting'>Generating</span> : <></>
                          }
                          {x.state == 'running' || x.state == 'start' ?
                            <span className='MailerMailingRowStatusSending'>Sending</span> : <></>
                          }
                          {x.state == 'sched' ?
                            <span className='MailerMailingRowStatusScheduled'>Scheduled</span> : <></>
                          }  
                          {x.state == 'paused' || x.state == 'pause_now' ?
                            <span className='MailerMailingRowStatusPaused'>Paused</span> : <></>
                          }                    
                          {x.state == 'cancel_processed' || x.state == 'cancel' ?
                            <span className='MailerMailingRowStatusCanceled'>Canceled</span> : <></>
                          }
                        </td>
     
                        <td>
                          <div className='MailerTableButtons'> 
                            {x.state == 'selectingTEST' ?
                              <div className='MailerMailingRowStopMailingButtonContainer'>
                                <span className='MailerMailingRowStopMailingButton' onClick={() => {stopMailing('pause', x.sched_id)}}><PauseRounded/></span>
                                <span className='MailerMailingRowStopMailingButton' onClick={() => {setDeleteMailingInfo({action: 'cancel', sched_id: x.sched_id, mail_id: x.mail_id}); setShowDeleteConfirmation(true)}}><StopRounded/></span>
                              </div>
                              :<></>
                            }
                            {x.state == 'running' || x.state == 'start' ?
                              <div className='MailerMailingRowStopMailingButtonContainer'>
                                <span className='MailerMailingRowStopMailingButton' onClick={() => {stopMailing('pause', x.sched_id)}}><PauseRounded/></span>
                                <span className='MailerMailingRowStopMailingButton' onClick={() => {setDeleteMailingInfo({action: 'cancel', sched_id: x.sched_id, mail_id: x.mail_id}); setShowDeleteConfirmation(true)}}><StopRounded/></span>
                              </div>
                              :<></>
                            }
                            {x.state == 'paused' ?
                              <div className='MailerMailingRowStopMailingButtonContainer'>
                                <span className='MailerMailingRowStopMailingButton' onClick={() => {stopMailing('resume', x.sched_id)}}><PlayArrowRounded/></span> 
                                <span className='MailerMailingRowStopMailingButton' onClick={() => {setDeleteMailingInfo({action: 'cancel', sched_id: x.sched_id, mail_id: x.mail_id}); setShowDeleteConfirmation(true)}}><StopRounded/></span>
                              </div>
                              : <></>
                            }  
                            {x.state == 'sched' ?
                              <span className='MailerMailingRowStopMailingButton' onClick={() => {setDeleteMailingInfo({action: 'delete', sched_id: x.sched_id, mail_id: x.mail_id}); setShowDeleteConfirmation(true)}}><DeleteRounded/></span> 
                              : <></>
                            }  
                          </div>
                        </td>


                      </tr>
                    )})}
                  </tbody>
                </table>


          
              </div>
            

          :<></>}



          {selectedLeftTab == 'accounts' ?
            <div>
              <table className='MailerAccountsTable'>
                <thead>
                  <tr>
                    <td></td>
                    <td>Account ID</td>
                    <td>Account Name</td>
                    <td>Status</td>
                    <td>Domain</td>
                    <td>Domain Days Used</td>
                    <td>Shared With</td>
                    <td></td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {finalSesAccounts.map((x) => { return (
                    <tr className='MailerAccountsTableRow' style={{display: userType == 'franchise' && !x.users.includes(uid) && 'none' }}>
                      <td>
                        <Avatar sx={{ color: hexCodesArray[letterToNumber((x.name).slice(0,1))], bgcolor:'#fff', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)' }}>{((x.name).slice(0,1)).toUpperCase()}</Avatar>
                      </td>
                      <td>{x.id}</td>
                      <td>{x.name}</td>
                      <td>{x.status}</td>
                      <td>{x.domain}</td>
                      <td>{x.domainDaysUsed}</td>
                      <td>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: "100%"}}>
                          {(x.users).map((y) => { 
                            return ( 
                              <Avatar key={y} sx={{ bgcolor: allUsers[y].hexCode, width: 25, height: 25, fontSize: 12 }}>{allUsers[y].initials}</Avatar>
                            );
                          })}
                        </div>
                      </td>
                      <td>
                        {x.status == 'Not Linked' ? 
                          <span className='MailerAccountsLinkAccountButton' onClick={() => {setSelectedAccountUsers(x.users); setSelectedAccountID(x.id); setSelectedAccount(x); setShowSharingOptions(true)}} >Link Account</span> 
                        : 
                          <span className='MailerAccountsLinkAccountButton' onClick={() => {setSelectedAccountUsers(x.users); setSelectedAccountID(x.id); setSelectedAccount(x); setShowSharingOptions(true)}} >Assign Mailing Specialist</span> 
                        }
                      </td>
                      <td><span className='MailerChartIconContainer' onClick={() => { getPastMailingsByAccount(x.id); setShowSendsChart(true); setSelectedAccountName(x.name)}}><BarChartRounded/></span></td>
                    </tr>
                  )})}
                </tbody>
              </table>
            </div>

          :<></>
          }




          {selectedLeftTab == 'autopauses' ?
            <div className='MailerSeedTestContainer'>
              <table className='MailerSeedTestTable' style={{width: 1300}}>
                <tbody>
                  <tr className='MailerSeedTestTableHeaderRow'>
                    <td style={{paddingBottom: 10}}>Mail ID</td>
                    <td style={{paddingBottom: 10}}>Deferrals Detected</td>
                    <td style={{paddingBottom: 10}}>Paused At</td>
                    <td style={{paddingBottom: 10}}>Pause Reason</td>
                    <td style={{paddingBottom: 10}}>Resumed At</td>
                    <td style={{paddingBottom: 10}}>Resume Reason</td>
                    <td style={{paddingBottom: 10}}>Action</td>
                  </tr>
                  {autopauses.map((x) => { return (
                    <tr>
                      <td style={{paddingBottom: 10}}>{x.mailID}</td>
                      <td style={{paddingBottom: 10}}>{x.count}</td>
                      <td style={{paddingBottom: 10}}>{x.pauseTimestamp ? (new Date(x.pauseTimestamp.seconds * 1000)).toLocaleString() : ''}</td>
                      <td style={{paddingBottom: 10}}>{x.pauseReason}</td>
                      <td style={{paddingBottom: 10}}>{x.resumeTimestamp ? (new Date(x.resumeTimestamp.seconds * 1000)).toLocaleString() : ''}</td>
                      <td style={{paddingBottom: 10}}>{x.resumeReason}</td>
                      <td style={{paddingBottom: 10}}>{x.latestAction}</td>
                    </tr>
                  )})}
                </tbody>
              </table>
            </div>
          :<></>
          }




          {selectedLeftTab == 'seedTests' ?
            <div className='MailerSeedTestContainer'>
              <table className='MailerSeedTestTable'>
                <tbody>
                  <tr className='MailerSeedTestTableHeaderRow'>
                    <td style={{paddingBottom: 40}}></td>
                    <td><span className='MailerSeedTestUserName'>Employee</span></td>
                    <td>IP Group</td>
                    <td>List</td>
                    <td></td>
                  </tr>
                  {allUsersObject.map((x) => { return (
                    <tr>
                      <td style={{paddingBottom: 10}}><Avatar sx={{ bgcolor: x.hexCode, width: 40, height: 40, fontSize: 18 }}>{x.initials}</Avatar></td>
                      <td style={{paddingBottom: 10}}><span className='MailerSeedTestUserName'>{x.firstName + " " + x.lastName}</span>  </td>
                      <td style={{paddingBottom: 10}}>{x.seedTestIPGroupName}</td>
                      <td style={{paddingBottom: 10}}>{x.seedTestListName}</td>
                      <td style={{paddingBottom: 10}}><span className='MailerSeedTestUserSettings' onClick={() => {setShowSeedTestSettings(true); setSelectedUserAccount(x)}}><SettingsRounded/></span></td>
                    </tr>
                  )})}
                </tbody>
              </table>
            </div>
          :<></>
          }







          {selectedLeftTab == 'adminSettings' ?
            <div className='MailerAdminSettingsContainer'>
              <div className='MailerAdminSettingsCreativesContainer'>
                <span className='MailerAdminSettingsTitle'>Default AI Creatives Settings</span>
                  <div className='ChatGPTNewCreativeInputWrapper'>
                    <span className='ChatGPTNewCreativeInputName'>Creativity</span>
                    <select
                      className='ChatGPTNewCreativeInputDropdown'
                      value={creativity}
                      onChange={(e) => setCreativity(e.target.value)}
                    >
                    <option value='1.0'>Very High</option>
                    <option value="0.8">High</option>
                    <option value="0.7">Normal</option>
                    <option value="0.5">Low</option>
                    <option value="0.1">Very Low</option>
                    </select>
                  </div>


                  <div className='ChatGPTNewCreativeInputWrapper'>
                    <span className='ChatGPTNewCreativeInputName'>AI Model</span>
                    <select
                      className='ChatGPTNewCreativeInputDropdown'
                      value={selectedAIModel}
                      onChange={(e) => setSelectedAIModel(e.target.value)}
                    >
                      <option value='gpt-4o'>GPT 4o (Always most updated)</option>
                      <option value='gpt-4o-2024-05-13'>GPT 4o-2024-05-13</option>
                      <option value='gpt-4-1106-preview'>GPT 4-Turbo V1 (1106) 128K/4K (Recommended)</option>
                      <option value='gpt-4-turbo-preview'>GPT 4-Turbo V2 (0125) 128K/4K</option>
                      <option value='gpt-4'>GPT 4 8K/8K</option>
                      <option value='gpt-3.5-turbo-16k'>GPT 3.5-Turbo 16K/16k</option>
                      <option value='gpt-3.5-turbo'>GPT 3.5-Turbo 16K/4K</option>
                    </select>
                  </div>

                  <Tooltip title="Automatically run prompts consecutively to get you a finished product with 1 click" placement="bottom">
                    <div className='ChatGPTNewCreativeRow'>
                      <span className='ChatGPTNewCreativeRowText'>Autopilot</span>
                      <Switch
                        onChange={(checked) => setAutopilot(checked)}
                        checked={autopilot}
                        offColor="#d9d9d9" onColor="#26cf5c" uncheckedIcon={false} checkedIcon={false}
                      />
                    </div>
                  </Tooltip>


                  {autopilot ?
                    <>

                      {/*
                        <Tooltip title="Run Autopilot with prompt chaining" placement="bottom">
                          <div className='ChatGPTNewCreativeRow'>
                            <span className='ChatGPTNewCreativeRowText'>Prompt Chaining</span>
                            <Switch
                              onChange={(checked) => setPromptChain(checked)}
                              checked={promptChain}
                              offColor="#d9d9d9" onColor="#26cf5c" uncheckedIcon={false} checkedIcon={false}
                            />
                          </div>
                        </Tooltip>
                      */}

                      <div className='ChatGPTNewCreativeInputWrapper'>
                        <span className='ChatGPTNewCreativeInputName'>How many times should MJML content be changed?</span>
                        <select
                          className='ChatGPTNewCreativeInputDropdown'
                          value={MJMLChangeCount}
                          onChange={(e) => setMJMLChangeCount(e.target.value)}
                        >
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value='5'>5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value='10'>10</option>
                        </select>
                      </div>

                      <div className='ChatGPTNewCreativeInputWrapper'>
                        <span className='ChatGPTNewCreativeInputName'>How many times should MJML be restructured?</span>
                        <select
                          className='ChatGPTNewCreativeInputDropdown'
                          value={MJMLRestructureCount}
                          onChange={(e) => setMJMLRestructureCount(e.target.value)}
                        >
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value='5'>5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value='10'>10</option>
                        </select>
                      </div>

                      <div className='ChatGPTNewCreativeInputWrapper'>
                        <span className='ChatGPTNewCreativeInputName'>How many times should HTML be restructured?</span>
                        <select
                          className='ChatGPTNewCreativeInputDropdown'
                          value={HTMLRestructureCount}
                          onChange={(e) => setHTMLRestructureCount(e.target.value)}
                        >
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value='5'>5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value='10'>10</option>
                        </select>
                      </div>

                      <div className='ChatGPTNewCreativeInputWrapper'>
                        <span className='ChatGPTNewCreativeInputName'>How many times should HTML be made more robust?</span>
                        <select
                          className='ChatGPTNewCreativeInputDropdown'
                          value={HTMLMoreRobustCount}
                          onChange={(e) => setHTMLMoreRobustCount(e.target.value)}
                        >
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value='5'>5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value='10'>10</option>
                        </select>
                      </div>



                      <Tooltip title='Automatically inserts templating variables, {{top}}, {{middle}}, and {{bottom}}, in last revision' placement="bottom">
                        <div className='ChatGPTNewCreativeRow'>
                          <span className='ChatGPTNewCreativeRowText'>Auto Insert Templating Variables</span>
                          <Switch
                            onChange={(checked) => {setAutoInsertTemplatingVariables(checked); setAutoInsertWeatherConvo(false);}}
                            checked={autoInsertTemplatingVariables}
                            offColor="#d9d9d9" onColor="#26cf5c" uncheckedIcon={false} checkedIcon={false}
                          />
                        </div>
                      </Tooltip>


                      <Tooltip title='Automatically inserts templating variables, {{middle}} and {{bottom}}, in a single div tag in last revision' placement="bottom">
                        <div className='ChatGPTNewCreativeRow'>
                          <span className='ChatGPTNewCreativeRowText'>Auto Insert Weather/Convo Variables</span>
                          <Switch
                            onChange={(checked) => {setAutoInsertWeatherConvo(checked); setAutoInsertTemplatingVariables(false);}}
                            checked={autoInsertWeatherConvo}
                            offColor="#d9d9d9" onColor="#26cf5c" uncheckedIcon={false} checkedIcon={false}
                          />
                        </div>
                      </Tooltip>



                      <Tooltip title='Automatically runs preset "find and replaces" and fills variables in last revision' placement="bottom">
                        <div className='ChatGPTNewCreativeRow'>
                          <span className='ChatGPTNewCreativeRowText'>Autofix</span>
                          <Switch
                            onChange={(checked) => setAutoApplyAutoFix(checked)}
                            checked={autoApplyAutoFix}
                            offColor="#d9d9d9" onColor="#26cf5c" uncheckedIcon={false} checkedIcon={false}
                          />
                        </div>
                      </Tooltip>
                    </>
                  :<></>}


                </div>



                <div className='MailerAdminSettingsCreativesContainer'>
                  <span className='MailerAdminSettingsTitle'>Default Mailing Settings</span>
                  <div className='ChatGPTCreateMailingSizeInputRow' style={{paddingTop: 15}}>
                    <div className='ChatGPTCreateMailingListRangeInputBoxContainer'>
                      <TextField style={{width:300}} label="Quota Increase Size" variant="outlined" value={quotaIncreasePercent} onChange={(e) => {setQuotaIncreasePercent(e.target.value)}} InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>}}/>
                    </div>
                    <span style={{opacity: 0}}>-</span>
                    <div className='ChatGPTCreateMailingListRangeInputBoxContainer'>
                      <TextField style={{width:300}} label="Max Quota" variant="outlined" value={maxQuota} onChange={(e) => {setMaxQuota(e.target.value)}}/>
                    </div>
                  </div>
                  <div className='ChatGPTCreateMailingSizeInputRow' style={{paddingTop: 15}}>
                    <div className='ChatGPTCreateMailingListRangeInputBoxContainer'>
                      <TextField style={{width:150}} label="Increment Hours" variant="outlined" value={incrementMailingHours} onChange={(e) => {setIncrementMailingHours(e.target.value)}} />
                    </div>
                    <span>Hours and</span>
                    <div className='ChatGPTCreateMailingListRangeInputBoxContainer'>
                      <TextField style={{width:150}} label="Increment Minutes" variant="outlined" value={incrementMailingMinutes} onChange={(e) => {setIncrementMailingMinutes(e.target.value)}} />
                    </div>
                    <span>Minutes +/-</span>
                    <div className='ChatGPTCreateMailingListRangeInputBoxContainer'>
                      <TextField style={{width:200}} label="Increment Randomness" variant="outlined" value={incrementMailingRandomness} onChange={(e) => {setIncrementMailingRandomness(e.target.value)}}/>
                    </div>
                    Minutes
                  </div>
                  <div className='ChatGPTCreateMailingSizeInputRow' style={{paddingTop: 15}}>
                    <div className='ChatGPTCreateMailingListRangeInputBoxContainer'>
                      <TextField style={{width:300}} label="Speed Incease" variant="outlined" value={increaseSpeedPercent} onChange={(e) => {setIncreaseSpeedPercent(e.target.value)}} InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>}}/>
                    </div>
                    <div className='ChatGPTCreateMailingListRangeInputBoxContainer'>
                      <TextField style={{width:300}} label="Max Speed" variant="outlined" value={maxSpeed} onChange={(e) => {setMaxSpeed(e.target.value)}} />
                    </div>
                  </div>
                  
                </div>


                


                <span className='MailerAdminSettingsSaveButton' onClick={() => {saveAdminSettings()}}>Save Default Settings</span>

          </div>
        :
          <></>
        }



        </div>
      </div>


















      <ReactModal className='PlaygroundSharingContainer' isOpen={showSharingOptions} onRequestClose={() => {setShowSharingOptions(false)}} ariaHideApp={false}>
        <div className='PlaygroundSharingContainerHeader'>
          <div className='PlaygroundSharingContainerHeaderIcon' onClick={() => {setShowSharingOptions(false)}}>
            <Close/>
          </div>
          <span className='PlaygroundSharingContainerHeaderText'>Share with</span>
          <div className='PlaygroundSharingContainerHeaderIcon' onClick={() => {saveAccount(); setShowSharingOptions(false)}}>
            <Save/>
          </div>
        </div>

        <div>
          {allUsersObject?.map((x) => { return (
            <div className={selectedAccountUsers?.includes(x.uid) ? 'PlaygroundSharingUserRowSelected' : 'PlaygroundSharingUserRow'} onClick={() => {adjustSharing(x.uid)}}>
              <div className='PlaygroundSharingUserRowAvatar' style={{backgroundColor: x.hexCode}}>
                {x.initials} 
              </div>
              <span className='PlaygroundSharingUserName' >{x.firstName + " " + x.lastName}</span>
              <span>{selectedAccountUsers?.includes(x.uid) ? <CheckCircle style={{color: '#29ae04', fontSize: 30}}/> :  <Check style={{color: '#ffffff00', fontSize: 30 }}/>}</span>
            </div>
          )})}
        </div>
      </ReactModal>







      <ReactModal className='MailerChartPopUpContainer' isOpen={showSendsChart} onRequestClose={() => {setShowSendsChart(false)}} ariaHideApp={false}>
        <div className='PlaygroundSharingContainerHeader'>
          <div className='PlaygroundSharingContainerHeaderIcon' onClick={() => {setShowSendsChart(false); setChartData()}}>
            <Close/>
          </div>
          <span className='PlaygroundSharingContainerHeaderText'>Sends By Day - {selectedAccountName}</span>
          <div className='PlaygroundSharingContainerHeaderIcon'>
            <span style={{opacity: 0}}><Save/></span>
          </div>
        </div>

        <div className='MailerChartPopUpContentContainer'>
          {chartData ? (
              <Bar data={chartData} options={options}/>
          ) : (
              <p>Loading chart data...</p>
          )}
        </div>
      </ReactModal>



      <ReactModal className='MailerChartPopUpContainer' isOpen={showSeedTestSettings} onRequestClose={() => {setShowSeedTestSettings(false)}} ariaHideApp={false}>
        <div className='PlaygroundSharingContainerHeader'>
          <div className='PlaygroundSharingContainerHeaderIcon' onClick={() => {setShowSeedTestSettings(false); setChartData()}}>
            <Close/>
          </div>
          <span className='PlaygroundSharingContainerHeaderText'>{selectedUserAccount.firstName} - Seed Test Settings</span>
          <div className='PlaygroundSharingContainerHeaderIcon' onClick={() => {saveSeedTestSettings(selectedUserAccount.uid)}}>
            <span><Save/></span>
          </div>
        </div>
        <div>
          <select className='MailerSeedTestSelect' value={selectedSesAccount} onChange={(e) => {setSelectedSesAccount(e.target.value)}}>
            {finalSesAccounts.map((x) => (
              <option key={x.id} value={x.id + '---' + x.name}>{x.name}</option>
            ))}
          </select>
          <select className='MailerSeedTestSelect' value={selectedList} onChange={(e) => {setSelectedList(e.target.value)}}>
            {lists?.map((x) => (
              <option key={x.id} value={x.master_id + '---' + x.table_name}>{x.table_name}</option>
            ))}
          </select>
        </div>
      </ReactModal>





      <ReactModal className='MailerChartPopUpContainer' isOpen={showDeleteConfirmation} onRequestClose={() => {setShowDeleteConfirmation(false)}} ariaHideApp={false}>
        <div className='PlaygroundSharingContainerHeader'>
          <div className='PlaygroundSharingContainerHeaderIcon' onClick={() => {setShowDeleteConfirmation(false)}}>
            <Close/>
          </div>
          <span className='PlaygroundSharingContainerHeaderText'>Are you sure you want to {deleteMailingInfo?.action} {deleteMailingInfo?.mail_id}?</span>
          <div className='PlaygroundSharingContainerHeaderIcon'>
            <span style={{opacity: 0}}><Save/></span>
          </div>
        </div>

        <div className='MailerChartPopUpContentContainer'>
          {deleteMailingInfo?.action == 'delete' ?
            <div className='MailerDeletePopupConfirmation'>
              <span className='MailerDeletePopupConfirmationButtonYes' onClick={() => {stopMailing(deleteMailingInfo.action, deleteMailingInfo.sched_id, deleteMailingInfo.mail_id); setShowDeleteConfirmation(false)}}>Yes, delete</span>
              <span className='MailerDeletePopupConfirmationButtonNo' onClick={() => {setShowDeleteConfirmation(false)}}>No, don't delete</span>          
            </div>
          : 
            <div className='MailerDeletePopupConfirmation'>
              <span className='MailerDeletePopupConfirmationButtonYes' onClick={() => {stopMailing(deleteMailingInfo.action, deleteMailingInfo.sched_id, deleteMailingInfo.mail_id); setShowDeleteConfirmation(false)}}>Yes, cancel</span>
              <span className='MailerDeletePopupConfirmationButtonNo' onClick={() => {setShowDeleteConfirmation(false)}}>No, don't cancel</span>          
            </div>
          }
        </div>
      </ReactModal>
















        <div className={`MailerResendMailingPopUpContainer ${showResendMailingPopup ? 'open' : ''}`}>
          <div className='MailerResendMailingContainerHeader'>
            <div className='MailerResendMailingHeaderIcon' onClick={() => {setShowResendMailingPopup(false)}}>
              <ChevronRightRounded/>
            </div>
            <span className='MailerResendMailingHeaderText'>Selected Mailings ({selectedMailIDs.length})</span>
            <div className='MailerResendMailingHeaderIcon' onClick={() => {setCalculateListRangesResponse({}); calculateListRangesForResends(selectedMailIDs, '', '', false)}}>
              <Tooltip title="Calculate All List Ranges">
                <CalculateOutlined/>
              </Tooltip>
            </div>
          </div>

          <div className='MailerResendMailingContentContainer'>
            
            {calculatingListRangesLoading && true == false && (
              <span className='MailerResendMailingEstimatedTimeRemainingText'>
                Estimated Time Remaining: {((selectedMailIDs.length - Object.keys(calculateListRangesResponse).length) * (Math.floor(Math.random() * 3) + 7)) === 0 ? 7 : ((selectedMailIDs.length - Object.keys(calculateListRangesResponse).length) * (Math.floor(Math.random() * 3) + 7))} seconds
              </span>
            )}

            {selectedMailings.map((x, index) => { return (
              <div className='MailerResendMailingRow'>
                <div className='MailerResendMailingAvatarContainer'>
                  <div className='MailerResendMailingAvatar'><Avatar sx={{ bgcolor: allUsersInitialsArray[(x?.bodyfile)?.slice(0,2)]?.hexCode, fontSize: 18 }}>{(x.bodyfile)?.slice(0,2)}</Avatar></div>
                  <div className='MailerResendMailingDeleteIcon' onClick={() => {adjustSelection(x.mail_id)}}><CloseRounded/></div>
                </div>
                <span className='MailerResendMailingPreviousMailID'>{x.mail_id}</span>  
                {/* <span className='MailerResendMailingPreviousMailName'>{x.mailing_name}</span>   */}
                <span className='MailerResendMailingPreviousAccount'>{(x.description.split('-')[0]).slice(0,30)}{(x.description.split('-')[0]).length > 30 ? '...' : ''}</span> 
                <div className='MailerResendMailingPreviousStartTimeContainer'> 
                  <span className='MailerResendMailingPreviousStartDate'>{formatDate(x.starttime)}</span> 
                  <span className='MailerResendMailingPreviousStartTime'>{formatTime(x.starttime)}</span> 
                </div>
                
        


                <br/>

                {calculatingListRangesLoading == true || calculateListRangesResponse[x.mail_id] ?
                  <div className='MailerResendMailingInfo'>


                    {calculatingListRangesLoading == true && Object.keys(calculateListRangesResponse).length < index ?
                      <span>Waiting to calculate</span>
                    :
                    <></>}

                    {calculateListRangesResponse[x.mail_id] ?
                    <>
                      <Tooltip title={
                        `Largest send size: ${new Intl.NumberFormat('en-US').format(calculateListRangesResponse[x.mail_id].largestSentInOneDay)}
                        Raw list size: ${new Intl.NumberFormat('en-US').format(calculateListRangesResponse[x.mail_id].rawSize)}
                        Percent removed in suppression: ${new Intl.NumberFormat('en-US', { style: 'percent' }).format(calculateListRangesResponse[x.mail_id].suppressionPercent / 100)}
                        Size before suppression: ${new Intl.NumberFormat('en-US').format(calculateListRangesResponse[x.mail_id].newQuotaBeforeSuppression)}
                        Size after suppression: ${new Intl.NumberFormat('en-US').format(calculateListRangesResponse[x.mail_id].newQuotaAfterSuppression)}
                        Last send start time: ${formatDate(calculateListRangesResponse[x.mail_id].lastSendStartTime)} at ${formatTime(calculateListRangesResponse[x.mail_id].lastSendStartTime)}
                        Quota Increase Percent: ${new Intl.NumberFormat('en-US', { style: 'percent' }).format(calculateListRangesResponse[x.mail_id].quotaIncreasePercent / 100)}
                        Increment time amount: ${calculateListRangesResponse[x.mail_id].incrementMailingHours} hours and ~ ${calculateListRangesResponse[x.mail_id].incrementMailingMinutes} minutes
                        `
                        }
                        PopperProps={{
                          style: { whiteSpace: 'pre-line' } 
                        }} >
                        <span className='MailerResendMailingInfoIcon'><InfoOutlined/></span>
                      </Tooltip>

                      <Tooltip title="Create Mailing">
                        <span className='MailerResendMailingSendIcon'>{calculateListRangesResponse[x.mail_id]?.sendMailingImmediately ? <SendRounded/> : <ScheduleSendRounded/>}</span>
                      </Tooltip>





                      {/* LIST SELECTION */}
                      {/* <ClickAwayListener onClickAway={() => {setChangeListForMailID('')}}> */}
                        <div className='MailerResendMailingListSectionContainer'>
                          <div className='MailerResendMailingListContainer' onClick={() => {
                            changeListForMailID == x.mail_id ? setChangeListForMailID('') : setChangeListForMailID(x.mail_id)
                            }}>
                            <span className='MailerResendMailingList'>{(calculateListRangesResponse[x.mail_id]?.listTableName)?.slice(0,30)}</span> 
                            <KeyboardArrowDownRounded className='MailerResendMailingListArrow'/>
                          </div>
                          {changeListForMailID == x.mail_id ? 
                            <div className='MailerResendMailingListDropdown'>
                              {lists.map((y) => { return (
                                <span className='MailerResendMailingListDropdownRow' style={{cursor: individualMailIDLoading != '' ? 'not-allowed' : 'pointer'}} onClick={() => {
                                  if (individualMailIDLoading !== '') {
                                    // Do nothing if individualMailIDLoading is not empty
                                    return;
                                  }
                                  setIndividualMailIDLoading(x.mail_id);
                                  calculateListRangesResponse[x.mail_id].listTableName = y.table_name; 
                                  //calculateListRangesResponse[x.mail_id].listIDs = y.master_id; 
                                  setChangeListForMailID(''); 
                                  calculateListRangesForResends(x.mail_id, y.table_name, y.master_id, true);
                                  
                                }}>{y.table_name}</span>
                              )})}
                            </div>
                            :
                            <></>
                          }
                        </div>
                      {/* </ClickAwayListener> */}
                      
                      
                      {/* LIST RANGES SELECTION */}
                        {changeListRangeForMailID == calculateListRangesResponse[x.mail_id] ?
                          <div className='MailerResendMailingListRangeEditRow'>
                            <input className='MailerResendMailingListRangeEditInput' value={changeListRangeStartValue} onChange={(e) => {setChangeListRangeStartValue(e.target.value)}}/>
                            -
                            <input className='MailerResendMailingListRangeEditInput' value={changeListRangeEndValue} onChange={(e) => {setChangeListRangeEndValue(e.target.value)}}/>
                            <span className='MailerResendMailingListRangeEditTotal'>({new Intl.NumberFormat('en-US').format(changeListRangeEndValue - changeListRangeStartValue)})</span>
                            <span
                              className='MailerResendMailingListRangeEditSaveIcon'
                              onClick={() => {
                                setCalculateListRangesResponse(prevState => ({
                                  ...prevState,
                                  [x.mail_id]: {
                                    ...prevState[x.mail_id],
                                    listRangeStart: changeListRangeStartValue,
                                    listRangeEnd: changeListRangeEndValue,
                                    listRangeSize: changeListRangeEndValue - changeListRangeStartValue
                                  }
                                }));
                              }}
                            >
                              <Save/>
                            </span>
                          </div>
                        :
                          <div className='MailerResendMailingListRange' onClick={() => {
                            
                            setChangeListRangeStartValue(calculateListRangesResponse[x.mail_id]?.listRangeStart); 
                            setChangeListRangeEndValue(calculateListRangesResponse[x.mail_id]?.listRangeEnd); 
                            setChangeListRangeForMailID(calculateListRangesResponse[x.mail_id]);
                            }}>
                            <span className='MailerResendMailingListRangeText'>
                              {new Intl.NumberFormat('en-US').format(calculateListRangesResponse[x.mail_id]?.listRangeStart) || ''} - {new Intl.NumberFormat('en-US').format(calculateListRangesResponse[x.mail_id]?.listRangeEnd) || ''} ({new Intl.NumberFormat('en-US').format(calculateListRangesResponse[x.mail_id]?.listRangeEnd - calculateListRangesResponse[x.mail_id]?.listRangeStart)})
                            </span>
                            <EditRounded className='MailerResendMailingListRangeIcon' fontSize='small'/>
                          </div>
                        }




                        
                      {/* MAILING TIME SELECTION */}
                      <div className=''>
                        {changeStartDateForMailID == x.mail_id ?
                          <div className='MailerResendMailingStartDateSelectorRow'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker 
                                slotProps={{ textField: { size: 'small'} }}
                                minDateTime={dayjs()} 
                                value={changeStartDateValue}
                                onChange={(newValue) => {setChangeStartDateValue(newValue)}}
                                sx={{maxWidth: 220}}
                    
                              />
                            </LocalizationProvider>
                            <div className='MailerResendMailingStartDateSelectionSaveIcon' onClick={() => {
                              setChangeStartDateForMailID('')
                              setCalculateListRangesResponse(prevState => ({
                                ...prevState,
                                [x.mail_id]: {
                                  ...prevState[x.mail_id],
                                  mailScheduleTimestamp: changeStartDateValue,
                                }
                              }));
                              }}>
                              <Save/>
                            </div>
                          </div>
                        : 
                          <>
                            <span className='MailerResendMailingSendNowText'>{calculateListRangesResponse[x.mail_id]?.sendMailingImmediately ? "Sending Immediately" : ""}</span>
                            <div className='MailerResendMailingStartDateHoverContainer' onClick={() => {setChangeStartDateValue(dayjs(calculateListRangesResponse[x.mail_id]?.mailScheduleTimestamp)); setChangeStartDateForMailID(x.mail_id)}}>
                              <span className='MailerResendMailingStartDate'>{formatDate(calculateListRangesResponse[x.mail_id]?.mailScheduleTimestamp)} at {formatTime(calculateListRangesResponse[x.mail_id]?.mailScheduleTimestamp)}</span>
                              <EditRounded className='MailerResendMailingStartDateEditIcon' fontSize='small'/>
                            </div> 
                          </>  
                        }
                      </div>


                      
                    </>
                    :<></>}

                    {(calculatingListRangesLoading == true && Object.keys(calculateListRangesResponse).length == index) || (individualMailIDLoading == x.mail_id) ?
                      <div className='MailerResendMailingLoadingContainer'>
                        <div className='MailerResendMailingProgressParent'>
                          <div className='MailerResendMailingProgressChild' style={{width: calculatingListRangeProgress + '%'}}></div>
                        </div>
                        <span>{calculatingListRangeProgress}%</span>
                      </div>
                    :
                    <></>}
                  
                    {/* 
                    <div>
                      <span>Past Mailings</span>
                      <span><KeyboardArrowDownRounded/></span>
                    </div>
                    */}
                  
                  </div>
                :
                  <span className='MailerResendMailingsCalculateIndividualButton' onClick={() => {calculateListRangesForResends(x.mail_id, '', '', true)}}>Calculate List</span>
                }

 
              </div>
            )})}

            <div style={{minHeight:20}}></div>
            {Object.keys(calculateListRangesResponse).length > 0 ?
              <span className='MailerResendMailingsSubmitButton' onClick={() => {setShowResendConfirmation(true)}}>Resend Mailings</span>
            :
              <></>
            }
          </div>
  
        </div>
      








        <ReactModal className='MailerChartPopUpContainer' isOpen={showResendConfirmation} onRequestClose={() => {setShowResendConfirmation(false)}} ariaHideApp={false}>
        <div className='PlaygroundSharingContainerHeader'>
          <div className='PlaygroundSharingContainerHeaderIcon' onClick={() => {setShowResendConfirmation(false)}}>
            <Close/>
          </div>
          <span className='PlaygroundSharingContainerHeaderText'>Are you sure you want to create these mailings?</span>
          <div className='PlaygroundSharingContainerHeaderIcon'>
            <span style={{opacity: 0}}><Save/></span>
          </div>
        </div>

        <div className='MailerChartPopUpContentContainer'>
         
          <div className='MailerDeletePopupConfirmation'>
            <span className='MailerDeletePopupConfirmationButtonYes' onClick={() => {resendMailingsLoop(); setShowResendConfirmation(false)}}>Yes, create {Object.keys(calculateListRangesResponse).length} mailing{Object.keys(calculateListRangesResponse).length > 1 ? 's' : ''}</span>
            <span className='MailerDeletePopupConfirmationButtonNo' onClick={() => {setShowResendConfirmation(false)}}>No, go back</span>          
          </div>
          
        </div>
      </ReactModal>
     










      <span className={`linksCopiedMessage ${showBottomMessage ? 'show' : ''}`}>{bottomMessage}</span>


    </div>
  );
}

export default Mailer;