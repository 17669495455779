import {React, useState} from 'react';
import './Links.css';

function Links() {
    const [links, setLinks] = useState([])
    const [usedIndexes, setUsedIndexes] = useState([]);
    const [showCopiedMessage, setShowCopiedMessage] = useState(false);

    console.log(showCopiedMessage)

    function handleUsedIndex(index) {
        if (usedIndexes.includes(index)) {
            // Already used
        } else {
            // Add the new index to the array of used indexes
            setUsedIndexes([...usedIndexes, index]);
        }
    }


    function getRandomNumber(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }

    async function getRandomWords(count = 32) {
        setUsedIndexes([])
        let words = [];
        let linksArray = [];
        try {
          // Replace 'your_api_endpoint' with the actual API endpoint that returns random words
          const response = await fetch(`https://random-word-api.vercel.app/api?words=${count}`);
          if (!response.ok) {
            throw new Error(`API call failed: ${response.status}`);
          }
          words = await response.json();
          console.log(words);
        } catch (error) {
          console.error('There was an error fetching the random words:', error);
        }

        for (let i = 0; i < 8; i++) {
            linksArray.push(words[(i * 4)] + '/' + words[(i * 4) + 1] + '/' + '{{trk}}' + '/' + words[(i * 4) + 2] + '/' + words[(i * 4) + 3]);
        }
        setLinks(linksArray)

      }


      function copyTextToClipboard(text) {
        var textArea = document.createElement("textarea");
    
        // Set the value of the textArea to the text to be copied
        textArea.value = text;
    
        // Make the textArea hidden
        textArea.style.position = "fixed";
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.width = "1px";
        textArea.style.height = "1px";
        textArea.style.opacity = "0";
    
        // Append the textArea to the document body
        document.body.appendChild(textArea);
    
        // Focus and select the text inside the textArea
        textArea.focus();
        textArea.select();
    
        try {
            // Execute the copy command
            var successful = document.execCommand('copy');
            var message = successful ? 'Copied to clipboard!' : 'Unable to copy to clipboard';
            console.log(message);
        } catch (err) {
            console.error('Unable to copy to clipboard:', err);
        }
    
        // Remove the textArea from the document body
        document.body.removeChild(textArea);
      }
      


      

    return ( 
        <div className='LinksContainer'>
            <span onClick={() => {getRandomWords()}} className='getLinksButton'>Generate Links</span>


            {links.map((x, index) => { return (
                <div
                className='linkRow'
                key={x}
              >
                <span
                  className={usedIndexes.includes(index) ? 'usedLinkContainer' : 'linkContainer'}
                  onClick={() => {copyTextToClipboard(x); handleUsedIndex(index);}}
                >
                  {usedIndexes.includes(index) ? <span className='usedLinkMarker'>USED</span> : <span className='usedLinkMarker'>NEW</span>}
                  <span onClick={() => {copyTextToClipboard(x); handleUsedIndex(index);}}>{x}</span>
                  <span
                    onClick={(e) => {
                      // Prevent the parent's onClick from firing when this span is clicked
                      e.stopPropagation();
                      handleUsedIndex(index);
                      copyTextToClipboard(x + '.png');
                    }}
                    className={usedIndexes.includes(index) ? 'selectedLinkTypeButton' : 'linkTypeButton'}
                  >
                    PNG
                  </span>
                  <span
                    onClick={(e) => {
                      // Prevent the parent's onClick from firing when this span is clicked
                      e.stopPropagation();
                      handleUsedIndex(index);
                      copyTextToClipboard(x + '.jpeg');
                    }}
                    className={usedIndexes.includes(index) ? 'selectedLinkTypeButton' : 'linkTypeButton'}
                  >
                    JPEG
                  </span>
                  <span
                    onClick={(e) => {
                      // Prevent the parent's onClick from firing when this span is clicked
                      e.stopPropagation();
                      handleUsedIndex(index);
                      const linkText = x.replace('{{trk}}/', ''); // Remove {{trk}} from x
                      copyTextToClipboard(linkText);
                    }}
                    className={usedIndexes.includes(index) ? 'selectedLinkTypeButton' : 'linkTypeButton'}
                  >
                    w/o {'{{trk}}'}
                  </span>
                </span>
              </div>
              
            )})}


        {showCopiedMessage ? 
            <span
            className='linksCopiedMessage'
            >
            Copied to clipboard
            </span>
        : ''}            
        </div>
     );
}

export default Links;