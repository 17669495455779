import { useEffect, useState } from "react";
import './FullAutoStep4.css';


function FullAutoStep4({selectedStep, setCreativity, creativity, setAutopilot, autopilot, setMJMLChangeCount, 
    MJMLChangeCount, setMJMLRestructureCount, MJMLRestructureCount,
    setHTMLRestructureCount, HTMLRestructureCount, setHTMLMoreRobustCount,
    HTMLMoreRobustCount, setAutoApplyAutoFix, autoApplyAutoFix,
    setAutoInsertTemplatingVariables, autoInsertTemplatingVariables, setSelectedAIModel, selectedAIModel, 
    maxRecipientsPerCreative, setMaxRecipientsPerCreative, autoInsertWeatherConvo, setAutoInsertWeatherConvo}) {

    







  return (
    <div className={selectedStep == 4 ? "FullAutoStep4Container" : "FullAutoStep4ContainerHidden"}>
        <div className="FullAutoStep4Left">
            <span className="FullAutoStep4LeftStepNumber">Step 4</span>
            <span className="FullAutoStep4LeftTitle">Artificial Intelligence settings</span>
        </div>
        <div className="FullAutoStep4Middle">
            {/* <span className="FullAutoStep4MiddleTitle">Select a mailing list</span>
            <span className="FullAutoStep4MiddleSubTitle">Select the mailing list you want to send your campaign to.</span> */}
            <div className="FullAutoStep4MiddleContainer">
                <table className="FullAutoStep4MiddleTable">
                    <tbody>
                        <tr>
                            <td className="FullAutoStep4MiddleTableTopic">Creativity</td>
                            <td>
                                <div className="FullAutoStep4MiddleTableOptionRow">
                                    <span className={creativity == '1.0' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setCreativity('1.0')}}>Very High</span>
                                    <span className={creativity == '0.8' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setCreativity('0.8')}}>High</span>
                                    <span className={creativity == '0.7' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setCreativity('0.7')}}>Normal</span>
                                    <span className={creativity == '0.5' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setCreativity('0.5')}}>Low</span>
                                    <span className={creativity == '0.1' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setCreativity('0.1')}}>Very Low</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="FullAutoStep4MiddleTableTopic">AI Model</td>
                            <td>
                                <div className="FullAutoStep4MiddleTableOptionRow">
                                    <span className={selectedAIModel == 'gpt-4o' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setSelectedAIModel('gpt-4o')}}>GPT-4o</span>
                                    <span className={selectedAIModel == 'gpt-4-turbo-preview' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setSelectedAIModel('gpt-4-turbo-preview')}}>GPT-4 Turbo</span>
                                    <span className={selectedAIModel == 'gpt-4' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setSelectedAIModel('gpt-4')}}>GPT-4</span>
                                    <span className={selectedAIModel == 'gpt-3.5-turbo-16k' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setSelectedAIModel('gpt-3.5-turbo-16k')}}>GPT-3.5 Turbo</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="FullAutoStep4MiddleTableTopic">Change Content (MJML)</td>
                            <td>
                                <div className="FullAutoStep4MiddleTableOptionRow">
                                    <span className={MJMLChangeCount == '0' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setMJMLChangeCount('0')}}>0</span>
                                    <span className={MJMLChangeCount == '1' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setMJMLChangeCount('1')}}>1</span>
                                    <span className={MJMLChangeCount == '2' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setMJMLChangeCount('2')}}>2</span>
                                    <span className={MJMLChangeCount == '3' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setMJMLChangeCount('3')}}>3</span>
                                    <span className={MJMLChangeCount == '4' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setMJMLChangeCount('4')}}>4</span>
                                    <span className={MJMLChangeCount == '5' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setMJMLChangeCount('5')}}>5</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="FullAutoStep4MiddleTableTopic">Restructure Content (MJML)</td>
                            <td>
                                <div className="FullAutoStep4MiddleTableOptionRow">
                                    <span className={MJMLRestructureCount == '0' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setMJMLRestructureCount('0')}}>0</span>
                                    <span className={MJMLRestructureCount == '1' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setMJMLRestructureCount('1')}}>1</span>
                                    <span className={MJMLRestructureCount == '2' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setMJMLRestructureCount('2')}}>2</span>
                                    <span className={MJMLRestructureCount == '3' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setMJMLRestructureCount('3')}}>3</span>
                                    <span className={MJMLRestructureCount == '4' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setMJMLRestructureCount('4')}}>4</span>
                                    <span className={MJMLRestructureCount == '5' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setMJMLRestructureCount('5')}}>5</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="FullAutoStep4MiddleTableTopic">Restructure Content (HTML)</td>
                            <td>
                                <div className="FullAutoStep4MiddleTableOptionRow">
                                    <span className={HTMLRestructureCount == '0' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setHTMLRestructureCount('0')}}>0</span>
                                    <span className={HTMLRestructureCount == '1' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setHTMLRestructureCount('1')}}>1</span>
                                    <span className={HTMLRestructureCount == '2' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setHTMLRestructureCount('2')}}>2</span>
                                    <span className={HTMLRestructureCount == '3' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setHTMLRestructureCount('3')}}>3</span>
                                    <span className={HTMLRestructureCount == '4' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setHTMLRestructureCount('4')}}>4</span>
                                    <span className={HTMLRestructureCount == '5' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setHTMLRestructureCount('5')}}>5</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="FullAutoStep4MiddleTableTopic">Lengthen Content (HTML)</td>
                            <td>
                                <div className="FullAutoStep4MiddleTableOptionRow">
                                    <span className={HTMLMoreRobustCount == '0' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setHTMLMoreRobustCount('0')}}>0</span>
                                    <span className={HTMLMoreRobustCount == '1' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setHTMLMoreRobustCount('1')}}>1</span>
                                    <span className={HTMLMoreRobustCount == '2' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setHTMLMoreRobustCount('2')}}>2</span>
                                    <span className={HTMLMoreRobustCount == '3' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setHTMLMoreRobustCount('3')}}>3</span>
                                    <span className={HTMLMoreRobustCount == '4' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setHTMLMoreRobustCount('4')}}>4</span>
                                    <span className={HTMLMoreRobustCount == '5' ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setHTMLMoreRobustCount('5')}}>5</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="FullAutoStep4MiddleTableTopic">Autopilot Mode</td>
                            <td>
                                <div className="FullAutoStep4MiddleTableOptionRow">
                                    <span className={autopilot ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setAutopilot(true)}}>On</span>
                                    <span className={autopilot == false ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setAutopilot(false)}}>Off</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="FullAutoStep4MiddleTableTopic">Templating Mode</td>
                            <td>
                                <div className="FullAutoStep4MiddleTableOptionRow">
                                    <span className={autoInsertTemplatingVariables ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setAutoInsertTemplatingVariables(true); setAutoInsertWeatherConvo(false)}}>On</span>
                                    <span className={autoInsertTemplatingVariables == false ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setAutoInsertTemplatingVariables(false)}}>Off</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="FullAutoStep4MiddleTableTopic">Weather/Convo Templating Mode</td>
                            <td>
                                <div className="FullAutoStep4MiddleTableOptionRow">
                                    <span className={autoInsertWeatherConvo ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setAutoInsertWeatherConvo(true); setAutoInsertTemplatingVariables(false)}}>On</span>
                                    <span className={autoInsertWeatherConvo == false ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setAutoInsertWeatherConvo(false)}}>Off</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="FullAutoStep4MiddleTableTopic">Autofix </td>
                            <td>
                                <div className="FullAutoStep4MiddleTableOptionRow">
                                    <span className={autoApplyAutoFix ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setAutoApplyAutoFix(true)}}>On</span>
                                    <span className={autoApplyAutoFix == false ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setAutoApplyAutoFix(false)}}>Off</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="FullAutoStep4MiddleTableTopic">Max Recipients/Creative </td>
                            <td>
                                <div className="FullAutoStep4MiddleTableOptionRow">
                                    <span className={maxRecipientsPerCreative != 20000000 ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setMaxRecipientsPerCreative(20000)}}>On</span>
                                    <span className={maxRecipientsPerCreative == 20000000 ? "FullAutoStep4MiddleTableOptionSelected" : "FullAutoStep4MiddleTableOption"} onClick={() => {setMaxRecipientsPerCreative(20000000)}}>Off</span>
                                    {maxRecipientsPerCreative != 20000000 && <input className="FullAutoStep4TextInput" type="text" placeholder="Search for an offer" onChange={(e) => {setMaxRecipientsPerCreative(Number(e.target.value))}} value={maxRecipientsPerCreative} />}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>


    </div>
  );
}


export default FullAutoStep4;