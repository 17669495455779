import React, { useEffect, useState } from "react";
import './FullAutoStep5.css';


function FullAutoStep5({selectedStep, fullAutoMailings, setFullAutoMailings, multipleOffersPerAccount, setMultipleOffersPerAccount, selectedAccounts, selectedOffers, 
    maxRecipientsPerCreative, setMaxRecipientsPerCreative, fullAutoComputedMailings, setFullAutoComputedMailings, rawMailingListSize, selectedMailingList }) {



    async function computeFullAutoMailings() {

        let runs = 0;

        // console.log(selectedOffers)
        const computedMailings = [];
        if (multipleOffersPerAccount) {
            let offerBeginning = 0;
            let offerEnd = 0;
            let totalQuotaRemaining = selectedAccounts.reduce((accumulator, currentItem) => {return accumulator + currentItem.newQuota}, 0)
            for (let i = 0; i < selectedAccounts.length; i++) {
                let rangeSizePerOffer = Math.ceil(selectedAccounts[i].newQuota / selectedOffers.length);
                let accountQuotaRemaining = selectedAccounts[i].newQuota;
                //for (let j = 0; j < selectedOffers.length; j++) {
                    //if (maxRecipientsPerCreative != false && rangeSizePerOffer > maxRecipientsPerCreative) {
                        // If the range size per offer is greater than the max recipients per creative, we need to split the range into multiple mailings
                        
                        console.log(rangeSizePerOffer, maxRecipientsPerCreative, selectedOffers.length, selectedAccounts.length)

                        let j = 0; //offer index
                        while (accountQuotaRemaining > 0 && runs < 200) {
                            let accountQuotaPerOfferRemaining = rangeSizePerOffer;

                            while (accountQuotaPerOfferRemaining > 0 && runs < 200 ) {
                                //console.log(j)
                                // console.log(accountQuotaRemaining)
                                // console.log(totalQuotaRemaining)
                                runs++
                                console.log(runs)

                                if (offerEnd == rawMailingListSize) {
                                    offerBeginning = 0;
                                } else {
                                    offerBeginning = offerEnd;
                                }

                                offerEnd = Math.floor(offerBeginning + maxRecipientsPerCreative);

                        
                                
                                if (offerEnd >= (rangeSizePerOffer * (j + 1))) {
                                    //offerEnd = rangeSizePerOffer * (j + 1);
                                }


                                if (offerEnd > rawMailingListSize) {
                                    offerEnd = rawMailingListSize
                                } else {
                                    offerEnd = Number(offerEnd.toFixed(0));
                                }

                                // if (offerEnd < offerBeginning) {
                                //     offerBeginning = 0;
                                //     offerEnd = maxRecipientsPerCreative;
                                // }


                                if (offerEnd - offerBeginning > accountQuotaPerOfferRemaining) {
                                    offerEnd = Math.ceil(offerBeginning + accountQuotaPerOfferRemaining);
                                }

                            

                                computedMailings.push({
                                    accountID: selectedAccounts[i].accountID,
                                    accountName: selectedAccounts[i].accountName,
                                    lastSendTime: new Date(selectedAccounts[i].lastSendTime),
                                    sendMailingAt: new Date(selectedAccounts[i].sendMailingAt.time),
                                    newQuota: selectedAccounts[i].newQuota,
                                    alreadySentToday: selectedAccounts[i].alreadySentToday,
                                    listRangeStart: offerBeginning,
                                    listRangeEnd: offerEnd,
                                    promptName: selectedOffers[j].prompt_name,
                                    id: selectedOffers[j].revisions[selectedOffers[j].revisions.length - 1],
                                    count: 1,
                                    promptRevisions: selectedOffers[j].revisions,
                                    epm: selectedOffers[j].epm,
                                    clicks: selectedOffers[j].total_clicks,
                                    payout: selectedOffers[j].total_payout,
                                    sent: selectedOffers[j].total_sent,
                                    mailingListID: selectedMailingList.master_id,
                                    mailingListName: selectedMailingList.table_name,
                                    rawMailingListSize: rawMailingListSize,
                                    position: 'top',
                                    testingQuotaRemaining: accountQuotaPerOfferRemaining,
                                });

                                accountQuotaRemaining -= Math.ceil(offerEnd - offerBeginning);
                                accountQuotaPerOfferRemaining -= Math.ceil(offerEnd - offerBeginning);
                                totalQuotaRemaining -= Math.ceil(offerEnd - offerBeginning);
                            }
                            j++;
                            
                        }
                    //} else {
                        // If the range size per offer is less than the max recipients per creative, we can send the entire range in one mailing
                        // offerBeginning = offerEnd;

                        // if (offerBeginning >= rawMailingListSize) {
                        //     offerBeginning = 0;
                        // }

                        // offerEnd = Math.floor(offerBeginning + rangeSizePerOffer);

                        // if (offerEnd > rawMailingListSize) {
                        //     offerEnd = (rawMailingListSize / selectedOffers.length) * j;
                        // } else {
                        //     offerEnd = Number(offerEnd.toFixed(0));
                        // }

                        // if (offerBeginning == offerEnd) {
                        //     console.log("Offer beginning is equal to offer end");
                        // }

                        // console.log("Offer beginning: ", offerBeginning);

                        // computedMailings.push({
                        //     accountID: selectedAccounts[i].accountID,
                        //     accountName: selectedAccounts[i].accountName,
                        //     lastSendTime: new Date(selectedAccounts[i].lastSendTime),
                        //     sendMailingAt: new Date(selectedAccounts[i].sendMailingAt.time),
                        //     newQuota: selectedAccounts[i].newQuota,
                        //     alreadySentToday: selectedAccounts[i].alreadySentToday,
                        //     listRangeStart: offerBeginning,
                        //     listRangeEnd: offerEnd,
                        //     promptName: selectedOffers[j].prompt_name,
                        //     promptID: selectedOffers[j].id,
                        //     id: selectedOffers[j].revisions[selectedOffers[j].revisions.length - 1],
                        //     count: 1,
                        //     epm: selectedOffers[j].epm,
                        //     clicks: selectedOffers[j].total_clicks,
                        //     payout: selectedOffers[j].total_payout,
                        //     sent: selectedOffers[j].total_sent,
                        //     mailingListID: selectedMailingList.master_id,
                        //     mailingListName: selectedMailingList.table_name,
                        //     rawMailingListSize: rawMailingListSize,
                        //     position: 'bottom',
                        //     testingQuotaRemaining: accountQuotaRemaining,

                        // });
                        // accountQuotaRemaining -= offerEnd - offerBeginning;

                    //}
                //}
            }
        } else {
            let offerBeginning = 0;
            let offerEnd = 0;
        
            for (let j = 0; j < selectedOffers.length; j++) {
                for (let i = 0; i < selectedAccounts.length; i++) {
                    let rangeSizePerOffer = Math.floor(selectedAccounts[i].newQuota / selectedOffers.length);;
                    if (maxRecipientsPerCreative != false && rangeSizePerOffer > maxRecipientsPerCreative) {

                        const numberOfMailingsPerOffer = Math.ceil(rangeSizePerOffer / maxRecipientsPerCreative);
                        for (let k = 0; k < numberOfMailingsPerOffer; k++) {

                
                            offerBeginning = offerEnd;
                
                            if (offerBeginning >= rawMailingListSize) {
                                offerBeginning = 0;
                            }
                
                            if ((offerBeginning + rangeSizePerOffer) <= rawMailingListSize) {
                                offerEnd = Math.floor(offerBeginning + maxRecipientsPerCreative);
                            } else {
                                offerEnd = rawMailingListSize;
                            }


                            
                
                            computedMailings.push({
                                accountID: selectedAccounts[i].accountID,
                                accountName: selectedAccounts[i].accountName,
                                lastSendTime: new Date(selectedAccounts[i].lastSendTime),
                                sendMailingAt: new Date(selectedAccounts[i].sendMailingAt.time),
                                newQuota: selectedAccounts[i].newQuota,
                                alreadySentToday: selectedAccounts[i].alreadySentToday,
                                listRangeStart: offerBeginning,
                                listRangeEnd: offerEnd,
                                promptName: selectedOffers[j].prompt_name,
                                id: selectedOffers[j].revisions[selectedOffers[j].revisions.length - 1],
                                count: 1,
                                promptRevisions: selectedOffers[j].revisions,
                                epm: selectedOffers[j].epm,
                                clicks: selectedOffers[j].total_clicks,
                                payout: selectedOffers[j].total_payout,
                                sent: selectedOffers[j].total_sent,
                                mailingListID: selectedMailingList.master_id,
                                mailingListName: selectedMailingList.table_name,
                                rawMailingListSize: rawMailingListSize,
                            });
                        }

                    } else {
                        let rangeSizePerOffer = Math.floor(selectedAccounts[i].newQuota / selectedOffers.length);;
            
                        offerBeginning = offerEnd;
            
                        if (offerBeginning >= rawMailingListSize) {
                            offerBeginning = 0;
                        }
            
                        if ((offerBeginning + rangeSizePerOffer) <= rawMailingListSize) {
                            offerEnd = Math.floor(offerBeginning + rangeSizePerOffer);
                        } else {
                            offerEnd = rawMailingListSize;
                        }
            
                        computedMailings.push({
                            accountID: selectedAccounts[i].accountID,
                            accountName: selectedAccounts[i].accountName,
                            lastSendTime: new Date(selectedAccounts[i].lastSendTime),
                            sendMailingAt: new Date(selectedAccounts[i].sendMailingAt.time),
                            newQuota: selectedAccounts[i].newQuota,
                            alreadySentToday: selectedAccounts[i].alreadySentToday,
                            listRangeStart: offerBeginning,
                            listRangeEnd: offerEnd,
                            promptName: selectedOffers[j].prompt_name,
                            id: selectedOffers[j].revisions[selectedOffers[j].revisions.length - 1],
                            count: 1,
                            promptRevisions: selectedOffers[j].revisions,
                            epm: selectedOffers[j].epm,
                            clicks: selectedOffers[j].total_clicks,
                            payout: selectedOffers[j].total_payout,
                            sent: selectedOffers[j].total_sent,
                            mailingListID: selectedMailingList.master_id,
                            mailingListName: selectedMailingList.table_name,
                            rawMailingListSize: rawMailingListSize,
                        });
                    }
                }
            }
        }



        if (!multipleOffersPerAccount) {
            // Correctly sorting the array by the string property 'promptName'
            console.log(computedMailings.sort((a, b) => a.promptName.localeCompare(b.promptName)));
            
            // Make sure to use the sorted array to set the state
            setFullAutoComputedMailings([...computedMailings].sort((a, b) => a.promptName.localeCompare(b.promptName)));
        } else {
            setFullAutoComputedMailings(computedMailings);
        }
        
    
        return true
    }
    


    useEffect(() => {
        if (selectedAccounts.length > 0 && selectedOffers.length > 0 && rawMailingListSize > 0) {
            computeFullAutoMailings();
        } 
    }, [selectedAccounts, selectedOffers, multipleOffersPerAccount, maxRecipientsPerCreative, rawMailingListSize])

    


    function formatDate(date) {
        const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const monthsOfYear = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
        const dayOfWeek = daysOfWeek[date.getDay()];
        const month = monthsOfYear[date.getMonth()];
        const day = date.getDate();
    
        return `${month} ${day}`;
    }

    function formatTime(date) {
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
    
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const minutesStr = minutes < 10 ? '0' + minutes : minutes;
    
        return `${hours}:${minutesStr} ${ampm}`;
    }





    const addedHeaders = new Set();

  return (
    <div className={selectedStep == 5 ? "FullAutoStep5Container" : "FullAutoStep5ContainerHidden"}>
        <div className="FullAutoStep5Left">
            <span className="FullAutoStep5LeftStepNumber">Step 5</span>
            <span className="FullAutoStep5LeftTitle">Review mailings</span>
        </div>
        <div className="FullAutoStep5Middle">
            {/* <span className="FullAutoStep5MiddleTitle">Select a mailing list</span>
            <span className="FullAutoStep5MiddleSubTitle">Select the mailing list you want to send your campaign to.</span> */}
            <div className="FullAutoStep5MiddleContainer">
                {fullAutoComputedMailings.map((x, index) => {
                    const shouldAddPromptHeader = !multipleOffersPerAccount && !addedHeaders.has(x.promptName);
                    const shouldAddAccountHeader = multipleOffersPerAccount && !addedHeaders.has(x.accountName);

                    if (shouldAddPromptHeader) {
                        addedHeaders.add(x.promptName);
                    }
                    if (shouldAddAccountHeader) {
                        addedHeaders.add(x.accountName);
                    }

                    return (
                        <React.Fragment key={index}>
                            {!multipleOffersPerAccount ? (
                                shouldAddPromptHeader ? (
                                    <span className="FullAutoStep5RowHeaderText">{x.promptName}</span>
                                ) : null
                            ) : (
                                shouldAddAccountHeader ? (
                                    <span className="FullAutoStep5RowHeaderText">{(x.accountName.substring(0, x.accountName.lastIndexOf("-"))).slice(0,32)}</span>
                                ) : null
                            )}

                            <div className="FullAutoStep5MailingCard">
                                <div className="FullAutoStep5MiddleMailingLeft">
                                    {!multipleOffersPerAccount ? (
                                        <span className="FullAutoStep5MailingCardName">{(x.accountName.substring(0, x.accountName.lastIndexOf("-"))).slice(0,40)}</span>
                                    ) : (
                                        <span className="FullAutoStep5MailingCardName">{x.promptName} {x.position}</span>
                                    )}
                                    <span className="FullAutoStep5MailingCardRange">
                                        Start {new Intl.NumberFormat('en-US').format(x.listRangeStart)} &bull; 
                                        End {new Intl.NumberFormat('en-US').format(x.listRangeEnd)} {x.testingQuotaRemaining}
                                    </span>
                                </div>
                                <div className="FullAutoStep5MiddleMailingRight">
                                    <span className="FullAutoStep5MiddleMailingRightText">{formatDate(new Date(x.sendMailingAt))}</span>
                                    <span className="" style={{ fontSize: 12 }}>{formatTime(new Date(x.sendMailingAt))}</span>
                                </div>
                                <div className="FullAutoStep5MiddleAccountBottomProgressParent">
                                    <div className="FullAutoStep5MiddleAccountBottomProgressChild" 
                                        style={{ 
                                            width: `${((x.listRangeEnd - x.listRangeStart) / rawMailingListSize) * 100}%`, 
                                            marginLeft: `${(x.listRangeStart / rawMailingListSize) * 100}%` 
                                        }}>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
      
    </div>
  );
}


export default FullAutoStep5;