import { useEffect, useState } from "react";
import './FullAutoStep2.css';
import { CheckCircle } from "@mui/icons-material";


function FullAutoStep2({selectedStep, activeAccounts, selectedAccounts, setSelectedAccounts}) {

    const [accountSearchText, setAccountSearchText] = useState('');
    const [filteredAccounts, setFilteredAccounts] = useState([]);

    
    useEffect(() => {
        setFilteredAccounts(activeAccounts)
        setSelectedAccounts(activeAccounts)
        setAccountSearchText('')
    }, [activeAccounts])


    useEffect(() => {
        if (accountSearchText !== '') {
          const filteredActiveAccounts = activeAccounts.filter(x => x.accountName.toLowerCase().includes(accountSearchText.toLowerCase()))
          setFilteredAccounts(filteredActiveAccounts)
        } else {
            setFilteredAccounts(activeAccounts)
        }
    }, [accountSearchText])



    async function adjustSelection(account) {
        let updatedAccounts = [];
        
        // Check if the account is already selected
        const isSelected = selectedAccounts.some(item => item.accountName === account.accountName);
    
        if (isSelected) {
            // If the account is already selected, remove it
            updatedAccounts = selectedAccounts.filter(item => item.accountName !== account.accountName);
        } else {
            // If the account is not selected, add it
            updatedAccounts = [...selectedAccounts, account];
        }
    
        setSelectedAccounts(updatedAccounts);
    }
    




  return (
    <div className={selectedStep == 2 ? "FullAutoStep2Container" : "FullAutoStep2ContainerHidden"}>
        <div className="FullAutoStep2Left">
            <span className="FullAutoStep2LeftStepNumber">Step 2</span>
            <span className="FullAutoStep2LeftTitle">Select SES Accounts</span>
            <span className="FullAutoStep2LeftDescription">Only showing SES accounts shared with Sendwell Automail</span>
        </div>
        <div className="FullAutoStep2Middle">
            {/* <span className="FullAutoStep2MiddleTitle">Select a mailing list</span>
            <span className="FullAutoStep2MiddleSubTitle">Select the mailing list you want to send your campaign to.</span> */}
            <div className="FullAutoStep2MiddleAccountContainer">
                <input className="FullAutoStep2MiddleAccountSearch" type="text" placeholder="Search for an SES Account" onChange={(e) => {setAccountSearchText(e.target.value)}} value={accountSearchText} />
                {filteredAccounts.map((x) => (
                    <div className="FullAutoStep2MiddleAccount" onClick={() => {adjustSelection(x);}}>
                        <div className="FullAutoStep2MiddleAccountLeft">
                            <span className="FullAutoStep2MiddleAccountName">{(x.accountName?.substring(0, (x.accountName?.lastIndexOf("-")))) != '' ? (x.accountName?.substring(0, (x.accountName?.lastIndexOf("-"))))?.slice(0,40) : x.accountName}</span>
                            <span className="FullAutoStep2MiddleAccountQuota">{new Intl.NumberFormat('en-US').format(x.newQuota)}</span>
                        </div>
                        <div className="FullAutoStep2MiddleAccountRight">
                            <span className="FullAutoStep2MiddleAccountRightText">{x.alreadySentToday ? "Sent" : "Ready"}</span>
                        </div>
                        <div className={selectedAccounts?.some(selected => selected.accountName === x.accountName) ? "FullAutoStep2MiddleAccountSelectedCheck" : "FullAutoStep2MiddleAccountHiddenCheck"}>
                            <CheckCircle style={{color: '#1dca23', fontSize: 30}}/>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        <div className="FullAutoStep2Right">

        </div>
    </div>
  );
}


export default FullAutoStep2;